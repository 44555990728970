import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as DeviceSelectors from '../../store/selectors/device.selector';
import * as DeviceActions from '../../store/actions/device.action';
import {AppState} from '../../store/appstate.model';
import {Observable} from 'rxjs';
import {Device} from '../../models/auth.model';

/**
 * Facade service for managing device tokens using NgRx.
 * Provides an abstraction layer for interacting with the NgRx store and selectors,
 * simplifying state management for components.
 */
@Injectable({
    providedIn: 'root',
})
export class DeviceFacadeService {
    /**
     * Observable stream of device from the store.
     */
    public device$ = this.store.select(DeviceSelectors.selectDeviceFeature);

    constructor(private store: Store<AppState>) {
    }

    /**
     * Dispatches an action to load authorization tokens into the store.
     */
    public loadData(): void {
        this.store.dispatch(DeviceActions.loadDevice());
    }

    /**
     * Dispatches an action to update an deviceId in the store.
     */
    public setDeviceId(device: Device): void {
        this.store.dispatch(DeviceActions.updateDevice({device}));
    }

    /**
     * Retrieves the id of the device
     */
    public getDeviceId(): Observable<string> {
        return this.store.pipe(select(DeviceSelectors.selectDeviceId));
    }

    /**
     * Dispatches an action to remove an authorization token from the store.
     * @param token The AuthorizationToken object to be removed.
     */
    public removeDeviceId(): void {
        this.store.dispatch(DeviceActions.removeDevice());
    }
}

