import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AnonymizationService {
    private readonly femaleNames = [
        'Rose',
        'Gänseblümchen',
        'Lilie',
        'Jasmin',
        'Veilchen',
        'Iris',
        'Sonnenblume',
        'Tulpe',
        'Ringelblume',
        'Mohn',
    ];
    private readonly femaleFirstnames = [
        'Krokus',
        'Narzisse',
        'Geranie',
        'Lavendel',
        'Primel',
    ];
    private readonly maleNames = [
        'Eiche',
        'Ahorn',
        'Kiefer',
        'Zeder',
        'Birke',
        'Esche',
        'Ulme',
        'Walnuss',
        'Mammutbaum',
        'Fichte',
    ];

    private readonly maleFirstnames = [
        'Lärche',
        'Buche',
        'Tanne',
        'Weide',
        'Pappel',
    ];

    constructor() {
    }

    anonymisiereName(originalName: string, gender: string): string {
        const hashValue = this.deterministischerHash(originalName);
        if (gender === 'male') {
            return this.maleNames[hashValue % this.maleNames.length];
        } else {
            return this.femaleNames[hashValue % this.femaleNames.length];
        }
    }

    anonymisiereFirstname(originalName: string, gender: string): string {
        const hashValue = this.deterministischerHash(originalName);
        if (gender === 'male') {
            return this.maleFirstnames[hashValue % this.maleFirstnames.length];
        } else {
            return this.femaleFirstnames[
            hashValue % this.femaleFirstnames.length
                ];
        }
    }

    // dayToPlus can be any integer between -5 and +4.
    adjustDobAndAdmissionDate(originalDate: string): string {
        const date = new Date(originalDate);
        const hashValue = this.deterministischerHash(originalDate);
        const dayToPlus = (hashValue % 10) - 5;
        date.setDate(date.getDate() + dayToPlus);
        return date.toISOString();
    }

    // Ensure at least 1 day after admission, up to 10 days
    adjustDischargeDate(admissionDate: string): string {
        const admission = new Date(admissionDate);
        const hashValue = this.deterministischerHash(admissionDate);
        const additionalDays = (hashValue % 10) + 1;
        admission.setDate(admission.getDate() + additionalDays);
        return admission.toISOString();
    }

    private deterministischerHash(input: string): number {
        let i,
            chr = 0;

        for (i = 0; i < input.length; i++) {
            chr += input.charCodeAt(i);
        }
        return chr;
    }
}
