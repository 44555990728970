import {
    VC_Case,
    Case_Overview,
    VC_Consultation,
    VC_Diagnostic,
    VC_Discharge,
    VC_Pathogen,
    VC_Post_Treatment,
    VC_Task,
    VC_Visit_Record,
    ViewContent,
    VcPatientListItem,
    VC_LabReport,
} from './../models/view-content.models/view-content.model';
import {
    VC_Diagnose,
    VC_Medical_History,
    Patient_Details,
    VC_Therapy,
} from '../models/view-content.models/view-content.model';
import { Place } from '../models/view-content.models/view-content-organization-domain.model';

export const dummyVC_Case: VC_Case = {
    id: '850e8400-e29b-41d4-a716-446655440333',
    related_case_id: '850e8400-e29b-41d4-a716-446655440333',
    locator: 'case.850e8400-e29b-41d4-a716-446655440333',
    owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
    owner_departments: ['cardiology'],
    main_owner_job_type: 'doctor',
    created_at: '2024-08-09T12:00:00Z',
    status: 'final',
    related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
    data: {
        patient: {
            surname: 'Mustermann',
            name: 'Max',
            gender: 'männlich', // Translated gender
            birth_date: '1980-01-01',
            valid_from: '2024-01-01',
            valid_until: '2025-01-01',
        },
        admission_date: '2024-08-01',
        discharge_date: '2024-08-10',
        planned_discharge_date: '2024-08-10',
        department: {
            centers: [
                {
                    location: {
                        corporation: {
                            code: 'CORP01',
                            shortName: 'KonzernKurz', // Translated shortName
                            name: 'Konzernname', // Translated name
                            validSince: '2024-01-01',
                        },
                        code: 'LOC01',
                        shortName: 'OrtKurz', // Translated shortName
                        name: 'Ortsname', // Translated name
                        validSince: '2024-01-01',
                    },
                    code: 'CTR01',
                    shortName: 'ZentrumKurz', // Translated shortName
                    name: 'Zentrumname', // Translated name
                    validSince: '2024-01-01',
                },
            ],
            code: 'DEPT01',
            shortName: 'AbteilungKurz',
            name: 'Abteilungsname',
            validSince: '2024-01-01',
        },
        start: '2024-08-01T08:00:00Z',
        end: '2024-08-10T15:00:00Z',
    },
    history: [],
};

export const dummyVC_Place: ViewContent<Place> = {
    id: '650e8400-e29b-41d4-a716-446655440111',
    related_case_id: 'related_case_id',
    locator: 'place-650e8400-e29b-41d4-a716-446655440111',
    owners: ['7edc1d64-c6f7-4b2d-8121-333cd9844573'],
    owner_departments: ['general'],
    main_owner_job_type: 'nurse',
    created_at: '2024-08-09T12:00:00Z',
    status: 'final',
    related_patient_id: '550e8400-e29b-41d4-a716-446655440000',

    data: {
        room: {
            serviceUnit: {
                departments: [
                    {
                        centers: [
                            {
                                location: {
                                    corporation: {
                                        code: 'CORP01',
                                        shortName: 'KonzernKurz',
                                        name: 'Konzernname',
                                        validSince: '2024-01-01',
                                    },
                                    code: 'LOC01',
                                    shortName: 'OrtKurz',
                                    name: 'Ortsname',
                                    validSince: '2024-01-01',
                                },
                                code: 'CTR01',
                                shortName: 'ZentrumKurz',
                                name: 'Zentrumname',
                                validSince: '2024-01-01',
                            },
                        ],
                        code: 'DEPT01',
                        shortName: 'AbteilungKurz',
                        name: 'Abteilungsname',
                        validSince: '2024-01-01',
                    },
                ],
                code: 'SU01',
                shortName: 'SUEinheitKurz',
                name: 'Dienstleistungseinheit Name',
                validSince: '2024-01-01',
            },
            code: 'ROOM01',
            shortName: 'ZimmerKurz',
            name: '201-C',
            validSince: '2024-01-01',
        },
        code: 'PLACE01',
        shortName: 'OrtKurz',
        name: 'Bett 01',
        validSince: '2024-01-01',
    },
    history: [],
};

export const dummyVC_Diagnose: VC_Diagnose = {
    id: 'a50e8400-e29b-41d4-a716-446655440555',
    locator: 'diagnose-a50e8400-e29b-41d4-a716-446655440555',
    owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
    owner_departments: ['radiology'],
    main_owner_job_type: 'doctor',
    created_at: '2024-08-09T12:00:00Z',
    status: 'final',
    related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
    data: {
        diagnose: 'Meningitis, Hypertonie',
        created_at: '2024-04-12T09:20:00Z',
    },
    related_case_id: 'related_case_id',
    history: [
        {
            data: {
                diagnose: 'Meningitis',
                created_at: '2024-04-12T09:20:00Z',
            },

            modifiedAt: '2024-04-12T09:20:00Z',
            modifiedBy: {
                userId: 'dummy_user_id',
                surname: 'Müller',
                name: 'Erika',
                validSince: '2020-01-01',
                validUntil: '2025-01-01',
            },
        },
        {
            data: {
                diagnose: 'Meningitis, Hypertonie',
                created_at: '2024-04-12T09:20:00Z',
            },

            modifiedAt: '2024-04-13T09:20:00Z',
            modifiedBy: {
                userId: 'dummy_user_id',
                surname: 'Müller',
                name: 'Erika',
                validSince: '2020-01-01',
                validUntil: '2025-01-01',
            },
        },
    ],
};

export const dummyVC_Patient_Details: Patient_Details = {
    case: dummyVC_Case,
    patient: {
        id: '550e8400-e29b-41d4-a716-446655440000',
        locator: 'patient.550e8400-e29b-41d4-a716-446655440000',
        owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
        owner_departments: ['cardiology'],
        main_owner_job_type: 'doctor',
        created_at: '2024-08-09T12:00:00Z',
        status: 'final',
        related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
        related_case_id: 'related_case_id',
        data: {
            surname: 'Mustermann',
            name: 'Max',
            gender: 'male',
            birth_date: '1980-01-01',
            valid_from: '2024-01-01',
            valid_until: '2025-01-01',
        },
        history: [],
        i18n: {
            de: {
                referenceId: 'Referenz-ID',
                medical_history: 'Anamnese',
                date: 'Datum',
                modifiedAt: 'Geändert am',
                modifiedBy: 'Geändert von',
                showThisAnamnesis: 'Diese Anamnese anzeigen',
                title: 'Titel',
                details: 'Details',
                task: 'Aufgabe',
                teams: 'Teams',
                registered: 'Registriert',
                conducted: 'Durchgeführt',
                goalDate: 'Zieldatum',
                goalDateOn: 'Zieldatum am',
                showDayCountForGoalDateAs:
                    'Zeige Tageszählung für Zieldatum als',
                dayCountDeactivate: 'Tageszählung deaktivieren',
                showJustInCurve: 'Nur in Kurve anzeigen',
                postTreatment: 'Nachbehandlung',
                consultations: 'Beratungen',
                performed: 'Durchgeführt',
                medication: 'Medikament',
                dosage: 'Dosierung',
                route: 'Verabreichungsweg',
                frequency: 'Häufigkeit',
                specialist: 'Spezialist',
                note: 'Notizen',
                setInCheckpad: 'Im Checkpad festlegen',
                dismissalExpectedOn: 'Entlassung erwartet am',
                comment: 'Kommentar',
                type: 'Typ',
                name: 'Name',
                dayCountForGoalDateShowAs:
                    'Tageszählung für Zieldatum anzeigen als',
                dayCountDeactivateOn: 'Tageszählung deaktivieren am',
                showOnlyInCurve: 'Nur in Kurve anzeigen',
                editor: 'Bearbeiter',
                created_at: 'Erstellt am',
                plannedDischarge: 'Geplante Entlassung',
                actualDischarge: 'Tatsächliche Entlassung',
                teams_labor: 'Labor',
                teams_punktion: 'Punktion',
                teams_doctors: 'Ärzte',
                teams_tx: 'TX',
                teams_nurse: 'Pflege',
                goal_date: 'Zieldatum',
                task_name: 'Aufgabe',
                priority: 'Priorität',
                done: 'Erledigt',
                contractor: 'Auftragnehmer',
                notes: 'Notiz',
                editor_surname: 'Nachname des Bearbeiters',
                editor_name: 'Vorname der Bearbeiters',
                contractor_surname: 'Nachname des Auftragnehmers',
                contractor_name: 'Vorname der Auftragnehmers',
                date_and_time: 'Datum und Zeit',
                diagnose: 'Diagnose',
            },
            en: {
                referenceId: 'Reference ID',
                medical_history: 'Medical history',
                date: 'Date',
                modifiedAt: 'Modified At',
                modifiedBy: 'Modified By',
                showThisAnamnesis: 'Show This Anamnesis',
                title: 'Title',
                details: 'Details',
                task: 'Task',
                teams: 'Teams',
                registered: 'Registered',
                conducted: 'Conducted',
                goalDate: 'Goal Date',
                goalDateOn: 'Goal Date On',
                showDayCountForGoalDateAs: 'Show Day Count For Goal Date As',
                dayCountDeactivate: 'Day Count Deactivate',
                showJustInCurve: 'Show Just In Curve',
                postTreatment: 'Post Treatment',
                consultations: 'Consultations',
                performed: 'Performed',
                medication: 'Medication',
                dosage: 'Dosage',
                route: 'Route',
                frequency: 'Frequency',
                specialist: 'Specialist',
                note: 'note',
                setInCheckpad: 'Set In Checkpad',
                dismissalExpectedOn: 'Dismissal Expected On',
                comment: 'Comment',
                type: 'Type',
                name: 'Name',
                dayCountForGoalDateShowAs: 'Day Count For Goal Date Show As',
                dayCountDeactivateOn: 'Day Count Deactivate On',
                showOnlyInCurve: 'Show Only In Curve',
                editor: 'Editor',
                created_at: 'Created at',
                plannedDischarge: 'Planned Discharge',
                actualDischarge: 'Actual Discharge',
                teams_labor: 'Labor',
                teams_punktion: 'Punktion',
                teams_doctors: 'Doctors',
                teams_tx: 'TX',
                teams_nurse: 'Nurse',
                goal_date: 'Goal Date',
                task_name: 'Task',
                priority: 'Priority',
                done: 'done',
                notes: 'Note',
                contractor: 'Contractor',
                editor_surname: 'Editor surname',
                editor_name: 'Editor name',
                contractor_surname: 'Contractor surname',
                contractor_name: 'Contractor name',
                date_and_time: 'Date and time',
                diagnose: 'Diagnose',
            },
        },
    },
    current_place: dummyVC_Place,
    last_diagnose: dummyVC_Diagnose,
};

export const dummyVC_Medical_History: VC_Medical_History = {
    id: '950e8400-e29b-41d4-a716-446655440444',
    locator: 'medical-history-950e8400-e29b-41d4-a716-446655440444',
    owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
    owner_departments: ['general_medicine'],
    main_owner_job_type: 'doctor',
    created_at: '2024-08-09T11:20:00Z',
    status: 'final',
    related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
    related_case_id: '',
    data: {
        medical_history:
            'Zunehmende Kopfschmerzen, Müdigkeit, Erbrechen und Durchfall seit 2 Tagen.',
        date: '2024-04-12',
        showThisAnamnesis: true,
        created_at: '2024-03-10T10:00:00Z',
    },
    history: [
        {
            data: {
                medical_history: 'Kopfschmerzen und Schwindel seit 3 Tagen.',
                date: '2024-03-10',
                showThisAnamnesis: true,
                created_at: '2024-03-10T10:00:00Z',
            },
            modifiedAt: '2024-03-10T11:00:00Z',
            modifiedBy: {
                userId: 'dummy_user_id',
                surname: 'Müller',
                name: 'Erika',
                validSince: '2020-01-01',
                validUntil: '2025-01-01',
            },
        },
        {
            data: {
                medical_history: 'Kopfschmerzen und Schwindel seit 6 Tagen.',
                date: '2024-03-10',
                showThisAnamnesis: true,
                created_at: '2024-03-10T10:00:00Z',
            },
            modifiedAt: '2024-03-13T11:00:00Z',
            modifiedBy: {
                userId: 'dummy_user_id',
                surname: 'Müller',
                name: 'Erika',
                validSince: '2020-01-01',
                validUntil: '2025-01-01',
            },
        },
        {
            data: {
                medical_history: 'Kopfschmerzen und Schwindel seit 1 Tag.',
                date: '2024-03-10',
                showThisAnamnesis: true,
                created_at: '2024-03-10T10:00:00Z',
            },
            modifiedAt: '2024-03-12T11:00:00Z',
            modifiedBy: {
                userId: 'dummy_user_id',
                surname: 'Müller',
                name: 'Erika',
                validSince: '2020-01-01',
                validUntil: '2025-01-01',
            },
        },
        {
            data: {
                medical_history: 'Kopfschmerzen und Schwindel seit 2 Tagen.',
                date: '2024-03-10',
                showThisAnamnesis: true,
                created_at: '2024-03-10T10:00:00Z',
            },
            modifiedAt: '2024-03-14T11:00:00Z',
            modifiedBy: {
                userId: 'dummy_user_id',
                surname: 'Müller',
                name: 'Erika',
                validSince: '2020-01-01',
                validUntil: '2025-01-01',
            },
        },
    ],
};

export const dummyVC_Therapy: VC_Therapy[] = [
    {
        id: 'b60e8400-e29b-41d4-a716-446655440666',
        locator: 'therapy-b60e8400-e29b-41d4-a716-446655440666',
        owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
        owner_departments: ['general_medicine'],
        main_owner_job_type: 'doctor',
        created_at: '2024-08-09T12:30:00Z',
        status: 'final',
        related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
        related_case_id: 'related_case_id',

        data: {
            title: 'Antibiotikatherapie',
            details:
                'Verabreicht zur Behandlung einer bakteriellen Infektion, die bei Meningitis vermutet wird, und 3-mal tägliche Temperaturkontrolle.',
            task: true,
            editor: {
                userId: 'dummy_user_id',
                surname: 'Müller',
                name: 'Erika',
                validSince: '2020-01-01',
                validUntil: '2025-01-01',
            },
            registered: true,
            conducted: false,
            goalDateOn: '2024-04-13T10:00:00Z',
            showDayCountForGoalDateAs: 'day0',
            dayCountDeactivateOn: '2024-04-14T10:00:00Z',
            showOnlyInCurve: false,
            created_at: '2024-04-12T09:30:00Z',
        },
        history: [
            {
                data: {
                    title: 'Antibiotikatherapie',
                    details:
                        'Verabreicht zur Behandlung einer bakteriellen Infektion, die bei Meningitis vermutet wird.',
                    task: true,
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Müller',
                        name: 'Erika',
                        validSince: '2020-01-01',
                        validUntil: '2025-01-01',
                    },
                    registered: true,
                    conducted: true,
                    goalDateOn: '2024-04-13T10:00:00Z',
                    showDayCountForGoalDateAs: 'day0',
                    dayCountDeactivateOn: '2024-04-14T10:00:00Z',
                    showOnlyInCurve: false,
                    created_at: '2024-04-12T09:30:00Z',
                },
                modifiedAt: '2024-04-12T09:30:00Z',
                modifiedBy: {
                    userId: 'dummy_user_id',
                    surname: 'Müller',
                    name: 'Erika',
                    validSince: '2020-01-01',
                    validUntil: '2025-01-01',
                },
            },
            {
                data: {
                    title: 'Antibiotikatherapie',
                    details:
                        'Verabreicht zur Behandlung einer bakteriellen Infektion, die bei Meningitis vermutet wird, und 3-mal tägliche Temperaturkontrolle.',
                    task: true,
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Müller',
                        name: 'Erika',
                        validSince: '2020-01-01',
                        validUntil: '2025-01-01',
                    },
                    registered: true,
                    conducted: false,
                    goalDateOn: '2024-04-13T10:00:00Z',
                    showDayCountForGoalDateAs: 'day0',
                    dayCountDeactivateOn: '2024-04-14T10:00:00Z',
                    showOnlyInCurve: false,
                    created_at: '2024-04-12T09:30:00Z',
                },
                modifiedAt: '2024-04-12T11:00:00Z',
                modifiedBy: {
                    userId: 'dummy_user_id',
                    surname: 'Müller',
                    name: 'Erika',
                    validSince: '2020-01-01',
                    validUntil: '2025-01-01',
                },
            },
        ],
    },
    {
        id: 'b60e8400-e29b-41d4-a716-446655440666',
        locator: 'therapy-b60e8400-e29b-41d4-a716-446655440666',
        owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
        owner_departments: ['general_medicine'],
        main_owner_job_type: 'doctor',
        created_at: '2024-04-15T09:00:00Z',
        status: 'final',
        related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
        related_case_id: '',
        data: {
            title: 'Antibiotikatherapie',
            details:
                'Verabreicht zur Behandlung einer bakteriellen Infektion, die bei Meningitis vermutet wird, und 3-mal tägliche Temperaturkontrolle.',
            task: true,
            editor: {
                userId: 'dummy_user_id',
                surname: 'Müller',
                name: 'Erika',
                validSince: '2020-01-01',
                validUntil: '2025-01-01',
            },
            registered: true,
            conducted: false,
            goalDateOn: '2024-04-13T10:00:00Z',
            showDayCountForGoalDateAs: 'day0',
            dayCountDeactivateOn: '2024-04-14T10:00:00Z',
            showOnlyInCurve: false,
            created_at: '2024-04-12T09:30:00Z',
        },
        history: [
            {
                data: {
                    title: 'Antibiotikatherapie',
                    details:
                        'Verabreicht antivirale Medikamente zur Behandlung einer Virusinfektion.',
                    task: true,
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Müller',
                        name: 'Erika',
                        validSince: '2020-01-01',
                        validUntil: '2025-01-01',
                    },
                    registered: true,
                    conducted: true,
                    goalDateOn: '2024-04-13T10:00:00Z',
                    showDayCountForGoalDateAs: 'day0',
                    dayCountDeactivateOn: '2024-04-14T10:00:00Z',
                    showOnlyInCurve: false,
                    created_at: '2024-04-12T09:30:00Z',
                },
                modifiedAt: '2024-04-15T09:00:00Z',
                modifiedBy: {
                    userId: 'dummy_user_id',
                    surname: 'Müller',
                    name: 'Erika',
                    validSince: '2020-01-01',
                    validUntil: '2025-01-01',
                },
            },
            {
                data: {
                    title: 'Antibiotikatherapie',
                    details:
                        'Verabreicht antivirale Medikamente zur Behandlung einer Virusinfektion.',
                    task: true,
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Müller',
                        name: 'Erika',
                        validSince: '2020-01-01',
                        validUntil: '2025-01-01',
                    },
                    registered: true,
                    conducted: false,
                    goalDateOn: '2024-04-13T10:00:00Z',
                    showDayCountForGoalDateAs: 'day0',
                    dayCountDeactivateOn: '2024-04-14T10:00:00Z',
                    showOnlyInCurve: false,
                    created_at: '2024-04-12T09:30:00Z',
                },
                modifiedAt: '2024-04-12T11:00:00Z',
                modifiedBy: {
                    userId: 'dummy_user_id',
                    surname: 'Müller',
                    name: 'Erika',
                    validSince: '2020-01-01',
                    validUntil: '2025-01-01',
                },
            },
        ],
    },
];

export const dummyVC_Visit_Record: VC_Visit_Record = {
    id: 'c70e8400-e29b-41d4-a716-446655440777',
    locator: 'visit-record-c70e8400-e29b-41d4-a716-446655440777',
    owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
    owner_departments: ['general_medicine'],
    main_owner_job_type: 'doctor',
    created_at: '2024-08-09T13:00:00Z',
    status: 'final',
    related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
    related_case_id: '',
    data: {
        note: 'Patient spricht gut auf die Behandlung an. Fortsetzung der aktuellen Therapie.',
        date_and_time: '2024-04-13T10:00:00Z',
    },
    history: [
        {
            data: {
                note: 'Patient spricht gut auf die Behandlung an. Fortsetzung der aktuellen Therapie.',
                date_and_time: '2024-04-13T10:00:00Z',
            },
            modifiedAt: '2024-04-13T10:00:00Z',
            modifiedBy: {
                userId: 'dummy_user_id',
                surname: 'Müller',
                name: 'Erika',
                validSince: '2020-01-01',
                validUntil: '2025-01-01',
            },
        },
        {
            data: {
                note: 'Patient spricht gut auf die Behandlung an.',
                date_and_time: '2024-04-13T06:00:00Z',
            },
            modifiedAt: '2024-04-13T06:00:00Z',
            modifiedBy: {
                userId: 'dummy_user_id',
                surname: 'Bauer',
                name: 'Thomas',
                validSince: '2020-01-01',
                validUntil: '2025-01-01',
            },
        },
    ],
};

export const dummyVC_Diagnostic: VC_Diagnostic[] = [
    {
        id: 'd80e8400-e29b-41d4-a716-446655440888',
        locator: 'diagnostic-d80e8400-e29b-41d4-a716-446655440888',
        owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
        owner_departments: ['radiology'],
        main_owner_job_type: 'doctor',
        created_at: '2024-08-09T13:30:00Z',
        status: 'final',
        related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
        related_case_id: 'related_case_id',
        data: {
            title: 'Blutkulturanalyse',
            details:
                'Analyse zeigt erhöhte Anzahl weißer Blutkörperchen, was auf eine mögliche bakterielle Infektion hinweist.',
            task: true,
            teams: {
                microbiology: true,
                hematology: false,
            },
            registered: true,
            conducted: true,
            goalDateOn: '2024-04-14T09:00:00Z',
            showDayCountForGoalDateAs: 'day1',
            dayCountDeactivate: '2024-04-15T12:00:00Z',
            showJustInCurve: false,
        },
        history: [
            {
                data: {
                    title: 'Blutkulturanalyse',
                    details:
                        'Analyse zeigt erhöhte Anzahl weißer Blutkörperchen, was auf eine mögliche bakterielle Infektion hinweist.',
                    task: true,
                    teams: {
                        microbiology: true,
                        hematology: false,
                    },
                    registered: true,
                    conducted: true,
                    goalDateOn: '2024-04-14T09:00:00Z',
                    showDayCountForGoalDateAs: 'day1',
                    dayCountDeactivate: '2024-04-15T12:00:00Z',
                    showJustInCurve: false,
                },
                modifiedAt: '2024-04-14T11:00:00Z',
                modifiedBy: {
                    userId: 'dummy_user_id',
                    surname: 'Müller',
                    name: 'Erika',
                    validSince: '2020-01-01',
                    validUntil: '2025-01-01',
                },
            },
        ],
    },
];

export const dummyVC_Consultation: VC_Consultation[] = [
    {
        id: 'e90e8400-e29b-41d4-a716-446655440999',
        locator: 'consultation-e90e8400-e29b-41d4-a716-446655440999',
        owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
        owner_departments: ['cardiology'],
        main_owner_job_type: 'doctor',
        created_at: '2024-08-09T14:00:00Z',
        status: 'final',
        related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
        related_case_id: 'related_case_id',
        data: {
            title: 'Kardiologische Konsultation',
            details:
                'Konsultation erforderlich, um das Bluthochdruckmanagement des Patienten zu bewerten.',
            task: true,
            editor: {
                id: '9fbd1d64-c6f7-4b2d-8121-333cd9844562',
                name: 'Dr. Erika Müller',
            },
            registered: true,
            performed: true,
            goalDateOn: '2024-04-14T10:00:00Z',
            dayCountForGoalDateShowAs: 'day1',
            dayCountDeactivate: 'on',
            dayCountDeactivateOn: '2024-04-15T12:00:00Z',
            showOnlyInCurve: false,
        },
        history: [
            {
                data: {
                    title: 'Kardiologische Konsultation',
                    details:
                        'Konsultation erforderlich, um das Bluthochdruckmanagement des Patienten zu bewerten.',
                    task: true,
                    editor: {
                        name: 'Dr. Erika Müller',
                    },
                    registered: true,
                    performed: true,
                    goalDateOn: '2024-04-14T10:00:00Z',
                    dayCountForGoalDateShowAs: 'day1',
                    dayCountDeactivate: 'on',
                    dayCountDeactivateOn: '2024-04-15T12:00:00Z',
                    showOnlyInCurve: false,
                },
                modifiedAt: '2024-04-14T12:00:00Z',
                modifiedBy: {
                    userId: 'dummy_user_id',
                    surname: 'Müller',
                    name: 'Erika',
                    validSince: '2020-01-01',
                    validUntil: '2025-01-01',
                },
            },
            {
                data: {
                    title: 'Kardiologische Konsultation',
                    details:
                        'Konsultation erforderlich, um das Bluthochdruckmanagement und den Blutdruck des Patienten zu bewerten.',
                    task: true,
                    editor: {
                        name: 'Dr. Erika Müller',
                    },
                    registered: true,
                    performed: true,
                    goalDateOn: '2024-04-15T10:00:00Z',
                    dayCountForGoalDateShowAs: 'day1',
                    dayCountDeactivate: 'on',
                    dayCountDeactivateOn: '2024-04-15T12:00:00Z',
                    showOnlyInCurve: false,
                },
                modifiedAt: '2024-04-14T16:00:00Z',
                modifiedBy: {
                    userId: 'dummy_user_id',
                    surname: 'Müller',
                    name: 'Erika',
                    validSince: '2020-01-01',
                    validUntil: '2025-01-01',
                },
            },
        ],
    },
];

export const dummyVC_Task: VC_Task[] = [
    {
        id: 'f10e8400-e29b-41d4-a716-446655441111',
        locator: 'task-f10e8400-e29b-41d4-a716-446655441111',
        owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
        owner_departments: ['general_medicine'],
        main_owner_job_type: 'doctor',
        created_at: '2024-08-09T14:30:00Z',
        status: 'final',
        related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
        related_case_id: 'related_case_id',
        data: {
            task_name: 'Überwachung der Vitalfunktionen',
            details:
                'Überwachen Sie die Vitalfunktionen alle 4 Stunden aufgrund des Verdachts auf Meningitis.',
            priority: 'high',
            editor: {
                userId: 'dummy_user_id',
                surname: 'Müller',
                name: 'Erika',
                validSince: '2020-01-01',
                validUntil: '2025-01-01',
            },
            goalDateOn: '2024-04-13T12:00:00Z',
            done: true,
            contractor: {
                userId: 'dummy_user_id',
                surname: 'Schmidt',
                name: 'Hans',
                validSince: '2021-01-01',
                validUntil: '2026-01-01',
            },
        },

        history: [
            {
                data: {
                    task_name: 'Überwachung der Vitalfunktionen',
                    details:
                        'Überwachen Sie die Vitalfunktionen alle 4 Stunden aufgrund des Verdachts auf Meningitis.',
                    priority: 'high',
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Müller',
                        name: 'Erika',
                        validSince: '2020-01-01',
                        validUntil: '2025-01-01',
                    },
                    goalDateOn: '2024-04-13T12:00:00Z',
                    done: true,
                    contractor: {
                        userId: 'dummy_user_id',
                        surname: 'Schmidt',
                        name: 'Hans',
                        validSince: '2021-01-01',
                        validUntil: '2026-01-01',
                    },
                },
                modifiedAt: '2024-04-13T14:00:00Z',
                modifiedBy: {
                    userId: 'dummy_user_id',
                    surname: 'Müller',
                    name: 'Erika',
                    validSince: '2020-01-01',
                    validUntil: '2025-01-01',
                },
            },
            {
                data: {
                    task_name: 'Überwachung der Vitalfunktionen',
                    details:
                        'Überwachen Sie die Vitalfunktionen alle & Stunden aufgrund des Verdachts auf Meningitis.',
                    priority: 'high',
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Müller',
                        name: 'Erika',
                        validSince: '2020-01-01',
                        validUntil: '2025-01-01',
                    },
                    goalDateOn: '2024-04-13T12:00:00Z',
                    done: true,
                    contractor: {
                        userId: 'dummy_user_id',
                        surname: 'Schmidt',
                        name: 'Hans',
                        validSince: '2021-01-01',
                        validUntil: '2026-01-01',
                    },
                },
                modifiedAt: '2024-04-14T14:00:00Z',
                modifiedBy: {
                    userId: 'dummy_user_id',
                    surname: 'Müller',
                    name: 'Erika',
                    validSince: '2020-01-01',
                    validUntil: '2025-01-01',
                },
            },
        ],
    },
];

export const dummyVC_Discharge: VC_Discharge[] = [
    {
        id: 'g20e8400-e29b-41d4-a716-446655442222',
        locator: 'discharge-g20e8400-e29b-41d4-a716-446655442222',
        owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
        owner_departments: ['general_medicine'],
        main_owner_job_type: 'doctor',
        created_at: '2024-08-09T15:00:00Z',
        status: 'final',
        related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
        data: {
            setInCheckpad: true,
            dismissalExpectedOn: '2024-04-15',
            comment:
                'Der Patient kann entlassen werden, keine weitere Behandlung erforderlich.',
        },
        related_case_id: 'related_case_id',
        history: [
            {
                data: {
                    setInCheckpad: true,
                    dismissalExpectedOn: '2024-04-15',
                    comment:
                        'Der Patient kann entlassen werden, keine weitere Behandlung erforderlich.',
                },
                modifiedAt: '2024-04-14T10:00:00Z',
                modifiedBy: {
                    userId: 'dummy_user_id',
                    surname: 'Müller',
                    name: 'Erika',
                    validSince: '2020-01-01',
                    validUntil: '2025-01-01',
                },
            },
        ],
    },
];

export const dummyVC_Post_Treatment: VC_Post_Treatment[] = [
    {
        id: 'h30e8400-e29b-41d4-a716-446655443333',
        locator: 'post-treatment-h30e8400-e29b-41d4-a716-446655443333',
        owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
        owner_departments: ['general_medicine'],
        main_owner_job_type: 'doctor',
        created_at: '2024-08-09T15:30:00Z',
        status: 'final',
        related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
        data: {
            postTreatment:
                'Patient wurde geraten, eine natriumarme Diät einzuhalten und den Blutdruck regelmäßig zu überwachen.',
        },
        history: [
            {
                data: {
                    postTreatment:
                        'Patient wurde geraten, eine natriumarme Diät einzuhalten und den Blutdruck regelmäßig zu überwachen.',
                },
                modifiedAt: '2024-04-15T12:00:00Z',
                modifiedBy: {
                    userId: 'dummy_user_id',
                    surname: 'Müller',
                    name: 'Erika',
                    validSince: '2020-01-01',
                    validUntil: '2025-01-01',
                },
            },
        ],

        related_case_id: 'related_case_id',
    },
];

export const dummyVC_Pathogen: VC_Pathogen[] = [
    {
        id: 'i40e8400-e29b-41d4-a716-446655444444',
        locator: 'pathogen-i40e8400-e29b-41d4-a716-446655444444',
        owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
        owner_departments: ['microbiology'],
        main_owner_job_type: 'doctor',
        created_at: '2024-08-09T16:00:00Z',
        status: 'final',
        related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
        related_case_id: 'related_case_id',
        data: {
            name: 'Streptococcus pneumoniae',
            type: 'Bakterien',
            date: '2024-04-13',
        },
        history: [
            {
                data: {
                    name: 'Streptococcus pneumoniae',
                    type: 'Bakterien',
                    date: '2024-04-13',
                },
                modifiedAt: '2024-04-14T10:00:00Z',
                modifiedBy: {
                    userId: 'dummy_user_id',
                    surname: 'Müller',
                    name: 'Erika',
                    validSince: '2020-01-01',
                    validUntil: '2025-01-01',
                },
            },
        ],
    },
];

export const dummyVC_Case_Overview: Case_Overview = {
    case: dummyVC_Case,
    medical_history: dummyVC_Medical_History,
    diagnose: dummyVC_Diagnose,
    therapy: dummyVC_Therapy,
    visit_record: dummyVC_Visit_Record,
    diagnostic: dummyVC_Diagnostic,
    consultation: dummyVC_Consultation,
    task: dummyVC_Task,
    discharge: dummyVC_Discharge,
    post_treatment: dummyVC_Post_Treatment,
    pathogen: dummyVC_Pathogen,
};

export const dummyVC_PatientList: VcPatientListItem[] = [
    {
        patient_details: {
            case: {
                id: '950e8400-e29b-41d4-a716-446655440444',
                locator: 'case.950e8400-e29b-41d4-a716-446655440444',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844563'],
                owner_departments: ['neurologie'],
                main_owner_job_type: 'doctor',
                created_at: '2024-08-11T12:00:00Z',
                status: 'final',
                related_patient_id: '9bb91e17-6079-4d1a-a931-dc631a3bf95c',
                related_case_id: '2efd3469-d287-4a39-b8dd-90efc644f825',
                data: {
                    patient: {
                        surname: 'Müller',
                        name: 'Klara',
                        gender: 'female',
                        birth_date: '1980-08-12',
                        valid_from: '2024-02-01',
                        valid_until: '2025-02-01',
                    },
                    admission_date: '2024-08-11',
                    discharge_date: '2024-08-15',
                    planned_discharge_date: '2024-08-15',
                    department: {
                        centers: [
                            {
                                location: {
                                    corporation: {
                                        code: 'CORP02',
                                        shortName: 'CorpNeu',
                                        name: 'Neurologie Gesellschaft',
                                        validSince: '2024-02-01',
                                    },
                                    code: 'LOC02',
                                    shortName: 'LocNeu',
                                    name: 'Neurologie Standort',
                                    validSince: '2024-02-01',
                                },
                                code: 'CTR02',
                                shortName: 'ZentrumNeu',
                                name: 'Neurologie Zentrum',
                                validSince: '2024-02-01',
                            },
                        ],
                        code: 'DEPT02',
                        shortName: 'AbtNeu',
                        name: 'Abteilung für Neurologie',
                        validSince: '2024-02-01',
                    },
                    start: '2024-08-11T08:00:00Z',
                    end: '2024-08-15T15:00:00Z',
                },
                history: [],
            },
            patient: {
                id: '9bb91e17-6079-4d1a-a931-dc631a3bf95c',
                locator: 'patient.9bb91e17-6079-4d1a-a931-dc631a3bf95c',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844563'],
                owner_departments: ['neurologie'],
                main_owner_job_type: 'doctor',
                created_at: '2024-04-09T10:00:00Z',
                status: 'final',
                related_patient_id: '9bb91e17-6079-4d1a-a931-dc631a3bf95c',
                related_case_id: '2efd3469-d287-4a39-b8dd-90efc644f825',
                data: {
                    surname: 'Müller',
                    name: 'Klara',
                    gender: 'female',
                    birth_date: '1980-08-12',
                    valid_from: '2024-02-01',
                    valid_until: '2025-02-01',
                },
                history: [],
                i18n: {},
            },
            current_place: {
                id: '650e8400-e29b-41d4-a716-446655440111',
                related_case_id: '2efd3469-d287-4a39-b8dd-90efc644f825',
                locator: 'place-650e8400-e29b-41d4-a716-446655440111',
                owners: ['7edc1d64-c6f7-4b2d-8121-333cd9844573'],
                owner_departments: ['allgemeinmedizin'],
                main_owner_job_type: 'nurse',
                created_at: '2024-08-09T12:00:00Z',
                status: 'final',
                related_patient_id: '2efd3469-d287-4a39-b8dd-90efc644f825',
                data: {
                    room: {
                        serviceUnit: {
                            departments: [
                                {
                                    centers: [
                                        {
                                            location: {
                                                corporation: {
                                                    code: 'CORP01',
                                                    shortName: 'CorpKurz',
                                                    name: 'Konzernname',
                                                    validSince: '2024-01-01',
                                                },
                                                code: 'LOC01',
                                                shortName: 'LocKurz',
                                                name: 'Standortname',
                                                validSince: '2024-01-01',
                                            },
                                            code: 'CTR01',
                                            shortName: 'ZentrumKurz',
                                            name: 'Zentrumsname',
                                            validSince: '2024-01-01',
                                        },
                                    ],
                                    code: 'DEPT01',
                                    shortName: 'AbtKurz',
                                    name: 'Abteilungsname',
                                    validSince: '2024-01-01',
                                },
                            ],
                            code: 'SU01',
                            shortName: 'SUKurz',
                            name: 'Dienstleistungseinheit 2',
                            validSince: '2024-01-01',
                        },
                        code: 'ROOM01',
                        shortName: 'ZimmerKurz',
                        name: '201-C',
                        validSince: '2024-01-01',
                    },
                    code: 'PLACE01',
                    shortName: 'OrtKurz',
                    name: 'Bett 01',
                    validSince: '2024-01-01',
                },
                history: [],
            },
            last_diagnose: dummyVC_Diagnose,
        },
        visit_record: {
            id: 'c70e8400-e29b-41d4-a716-446655440779',
            locator: 'visit-record-c70e8400-e29b-41d4-a716-446655440779',
            owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844563'],
            owner_departments: ['neurologie'],
            main_owner_job_type: 'doctor',
            created_at: '2024-08-10T13:00:00Z',
            status: 'final',
            related_patient_id: '9bb91e17-6079-4d1a-a931-dc631a3bf95c',
            related_case_id: '2efd3469-d287-4a39-b8dd-90efc644f825',
            data: {
                note: 'Patient berichtete von Verbesserungen nach der Medikation.',
                date_and_time: '2024-04-14T11:00:00Z',
            },
            history: [
                {
                    data: {
                        note: 'Patient berichtete von Verbesserungen nach der Medikation.',
                        date_and_time: '',
                    },
                    modifiedAt: '2024-04-14T11:00:00Z',
                    modifiedBy: {
                        userId: 'dummy_user_id',
                        surname: 'Braun',
                        name: 'Emilia',
                        validSince: '2021-02-01',
                        validUntil: '2026-02-01',
                    },
                },
            ],
            i18n: {
                de: { note: 'Notizen', date_and_time: 'Datum und Zeit' },
                en: { note: 'Note', date_and_time: 'Date and time' },
            },
        },
        tasks: [
            {
                id: 'f10e8400-e29b-41d4-a716-446655441113',
                locator: 'task-f10e8400-e29b-41d4-a716-446655441113',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844563'],
                owner_departments: ['neurologie'],
                main_owner_job_type: 'doctor',
                created_at: '2024-08-10T14:30:00Z',
                status: 'final',
                related_patient_id: '9bb91e17-6079-4d1a-a931-dc631a3bf95c',
                related_case_id: '2efd3469-d287-4a39-b8dd-90efc644f825',
                data: {
                    task_name: 'Tägliche Kopfschmerzüberwachung',
                    details:
                        'Überwachen Sie die Schwere und Häufigkeit der Kopfschmerzen.',
                    priority: 'high',
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Braun',
                        name: 'Emilia',
                        validSince: '2021-02-01',
                        validUntil: '2026-02-01',
                    },
                    goalDateOn: '2024-04-14T12:00:00Z',
                    done: false,
                    contractor: {
                        userId: 'dummy_user_id',
                        surname: 'Schneider',
                        name: 'Sara',
                        validSince: '2022-01-01',
                        validUntil: '2027-01-01',
                    },
                },
                history: [
                    {
                        data: {
                            task_name: 'Tägliche Kopfschmerzüberwachung',
                            details:
                                'Überwachen Sie die Schwere und Häufigkeit der Kopfschmerzen.',
                            priority: 'high',
                            editor: {
                                userId: 'dummy_id',
                                surname: 'Braun',
                                name: 'Emilia',
                                validSince: '2021-02-01',
                                validUntil: '2026-02-01',
                            },
                            goalDateOn: '2024-04-14T12:00:00Z',
                            done: false,
                            contractor: {
                                userId: 'dummy_id',
                                surname: 'Schneider',
                                name: 'Sara',
                                validSince: '2022-01-01',
                                validUntil: '2027-01-01',
                            },
                        },
                        modifiedAt: '2024-04-14T14:00:00Z',
                        modifiedBy: {
                            userId: 'dummy_user_id',
                            surname: 'Braun',
                            name: 'Emilia',
                            validSince: '2021-02-01',
                            validUntil: '2026-02-01',
                        },
                    },
                ],
                i18n: {
                    de: {
                        task_name: 'Notizen',
                        details: 'Datum und Zeit',
                        priority: 'Priorität',
                        goal_date: 'Zieldatum',
                        done: 'Erledigt',
                        notes: 'Notizen',
                        contractor: 'Auftragnehmer',
                        editor_surname: 'Nachname des Bearbeiters',
                        editor_name: 'Vorname des Bearbeiters',
                        contractor_surname: 'Nachname des Auftragnehmers',
                        contractor_name: 'Vorname des Auftragnehmers',
                    },
                    en: {
                        task_name: 'Note',
                        details: 'Date and time',
                        priority: 'Priority',
                        goal_date: 'Goal Date',
                        done: 'Done',
                        notes: 'Note',
                        contractor: 'Contractor',
                        editor_surname: 'Editor surname',
                        editor_name: 'Editor name',
                        contractor_surname: 'Contractor surname',
                        contractor_name: 'Contractor name',
                    },
                },
            },
        ],
    },
    {
        patient_details: {
            case: {
                id: '950e8400-e29b-41d4-a716-446655440444',
                locator: 'case.950e8400-e29b-41d4-a716-446655440444',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844563'],
                owner_departments: ['neurology'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-11T12:00:00Z',
                status: 'final', // Not translated
                related_patient_id: '9bb91e17-6079-4d1a-a931-dc631a3bf95c',
                related_case_id: 'cd6f1b50-9823-4c02-989a-13a38be8dfdd',
                data: {
                    patient: {
                        surname: 'Schmidt', // Translated surname
                        name: 'Jane', // No need to translate
                        gender: 'female', // Translated gender
                        birth_date: '1980-08-12',
                        valid_from: '2024-02-01',
                        valid_until: '2025-02-01',
                    },
                    admission_date: '2024-08-11',
                    discharge_date: '2024-08-15',
                    planned_discharge_date: '2024-08-15',
                    department: {
                        centers: [
                            {
                                location: {
                                    corporation: {
                                        code: 'CORP02',
                                        shortName: 'CorpNeu',
                                        name: 'Neurologische Gesellschaft', // Translated name
                                        validSince: '2024-02-01',
                                    },
                                    code: 'LOC02',
                                    shortName: 'LocNeu',
                                    name: 'Neurologischer Standort', // Translated name
                                    validSince: '2024-02-01',
                                },
                                code: 'CTR02',
                                shortName: 'ZentrumNeu', // Translated shortName
                                name: 'Neurologisches Zentrum', // Translated name
                                validSince: '2024-02-01',
                            },
                        ],
                        code: 'DEPT02',
                        shortName: 'AbtNeu', // Translated shortName
                        name: 'Abteilung für Neurologie', // Translated name
                        validSince: '2024-02-01',
                    },
                    start: '2024-08-11T08:00:00Z',
                    end: '2024-08-15T15:00:00Z',
                },
                history: [],
            },
            patient: {
                id: '9bb91e17-6079-4d1a-a931-dc631a3bf95c',
                locator: 'patient.9bb91e17-6079-4d1a-a931-dc631a3bf95c',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844563'],
                owner_departments: ['neurology'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-04-09T10:00:00Z',
                status: 'final', // Not translated
                related_patient_id: '9bb91e17-6079-4d1a-a931-dc631a3bf95c',
                related_case_id: 'cd6f1b50-9823-4c02-989a-13a38be8dfdd',
                data: {
                    surname: 'Schmidt', // Translated surname
                    name: 'Jane', // No need to translate
                    gender: 'female', // Translated gender
                    birth_date: '1980-08-12',
                    valid_from: '2024-02-01',
                    valid_until: '2025-02-01',
                },
                history: [],
                i18n: {},
            },
            current_place: dummyVC_Place, // No changes, as per previous pattern
            last_diagnose: dummyVC_Diagnose, // No changes, as per previous pattern
        },
        visit_record: {
            id: 'c70e8400-e29b-41d4-a716-446655440779',
            locator: 'visit-record-c70e8400-e29b-41d4-a716-446655440779',
            owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844563'],
            owner_departments: ['neurology'], // Not translated
            main_owner_job_type: 'doctor', // Not translated
            created_at: '2024-08-10T13:00:00Z',
            status: 'final', // Not translated
            related_patient_id: '9bb91e17-6079-4d1a-a931-dc631a3bf95c',
            related_case_id: 'cd6f1b50-9823-4c02-989a-13a38be8dfdd',
            data: {
                note: 'Patientin berichtete von einer Besserung nach der Medikation.', // Translated note
                date_and_time: '2024-08-10T13:00:00Z',
            },
            history: [
                {
                    data: {
                        note: 'Patientin berichtete von einer Besserung nach der Medikation.', // Translated note
                        date_and_time: '',
                    },
                    modifiedAt: '2024-04-14T11:00:00Z',
                    modifiedBy: {
                        userId: 'dummy_user_id',
                        surname: 'Braun', // Translated surname
                        name: 'Emilia', // Translated name
                        validSince: '2021-02-01',
                        validUntil: '2026-02-01',
                    },
                },
            ],
            i18n: {
                de: { note: 'Notizen', date_and_time: 'Datum und Zeit' },
                en: { note: 'Note', date_and_time: 'Date and time' },
            },
        },
        tasks: [
            {
                id: 'f10e8400-e29b-41d4-a716-446655441113',
                locator: 'task-f10e8400-e29b-41d4-a716-446655441113',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844563'],
                owner_departments: ['neurology'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-10T14:30:00Z',
                status: 'final', // Not translated
                related_patient_id: '9bb91e17-6079-4d1a-a931-dc631a3bf95c',
                related_case_id: 'cd6f1b50-9823-4c02-989a-13a38be8dfdd',
                data: {
                    task_name: 'Tägliche Überwachung der Kopfschmerzen', // Translated task name
                    details:
                        'Überwachung der Schwere und Häufigkeit der Kopfschmerzen.', // Translated details
                    priority: 'high', // Translated priority
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Braun', // Translated surname
                        name: 'Emilia', // Translated name
                        validSince: '2021-02-01',
                        validUntil: '2026-02-01',
                    },
                    goalDateOn: '2024-04-14T12:00:00Z',
                    done: false,
                    contractor: {
                        userId: 'dummy_user_id',
                        surname: 'Johansson', // Translated surname
                        name: 'Sarah', // No need to translate
                        validSince: '2022-01-01',
                        validUntil: '2027-01-01',
                    },
                },
                history: [
                    {
                        data: {
                            task_name: 'Tägliche Überwachung der Kopfschmerzen', // Translated task name
                            details:
                                'Überwachung der Schwere und Häufigkeit der Kopfschmerzen.', // Translated details
                            priority: 'high', // Translated priority
                            editor: {
                                userId: 'dummy_id',
                                surname: 'Braun', // Translated surname
                                name: 'Emilia', // Translated name
                                validSince: '2021-02-01',
                                validUntil: '2026-02-01',
                            },
                            goalDateOn: '2024-04-14T12:00:00Z',
                            done: false,
                            contractor: {
                                userId: 'dummy_id',
                                surname: 'Johansson', // Translated surname
                                name: 'Sarah', // No need to translate
                                validSince: '2022-01-01',
                                validUntil: '2027-01-01',
                            },
                        },
                        modifiedAt: '2024-04-14T14:00:00Z',
                        modifiedBy: {
                            userId: 'dummy_user_id',
                            surname: 'Braun', // Translated surname
                            name: 'Emilia', // Translated name
                            validSince: '2021-02-01',
                            validUntil: '2026-02-01',
                        },
                    },
                ],
                i18n: {
                    de: {
                        task_name: 'Aufgabe', // Translated
                        details: 'Details', // Translated
                        priority: 'Priorität', // Translated
                        goal_date: 'Zieldatum', // Translated
                        done: 'Erledigt', // Translated
                        notes: 'Notizen', // Translated
                        contractor: 'Auftragnehmer', // Translated
                        editor_surname: 'Nachname des Bearbeiters', // Translated
                        editor_name: 'Vorname des Bearbeiters', // Translated
                        contractor_surname: 'Nachname des Auftragnehmers', // Translated
                        contractor_name: 'Vorname des Auftragnehmers', // Translated
                    },
                    en: {
                        task_name: 'Task name',
                        details: 'Details',
                        priority: 'Priority',
                        goal_date: 'Goal Date',
                        done: 'Done',
                        notes: 'Note',
                        contractor: 'Contractor',
                        editor_surname: 'Editor surname',
                        editor_name: 'Editor name',
                        contractor_surname: 'Contractor surname',
                        contractor_name: 'Contractor name',
                    },
                },
            },
        ],
    },
    {
        patient_details: {
            case: {
                id: 'a60e8400-e29b-41d4-a716-446655440555',
                locator: 'case.a60e8400-e29b-41d4-a716-446655440555',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844564'],
                owner_departments: ['orthopedics'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-12T12:00:00Z',
                status: 'final', // Not translated
                related_patient_id: 'fd4d1ade-7605-45d3-a495-c98a2bdabd11',
                related_case_id: '80b09180-0fb5-475c-afbc-043513cee4b9',
                data: {
                    patient: {
                        surname: 'Johannsen', // Translated surname
                        name: 'David', // No need to translate
                        gender: 'male', // Translated gender
                        birth_date: '1980-02-28',
                        valid_from: '2024-03-01',
                        valid_until: '2025-03-01',
                    },
                    admission_date: '2024-08-12',
                    discharge_date: '2024-08-20',
                    planned_discharge_date: '2024-08-20',
                    department: {
                        centers: [
                            {
                                location: {
                                    corporation: {
                                        code: 'CORP03',
                                        shortName: 'CorpOrtho',
                                        name: 'Orthopädische Gesellschaft', // Translated name
                                        validSince: '2024-03-01',
                                    },
                                    code: 'LOC03',
                                    shortName: 'LocOrtho',
                                    name: 'Orthopädischer Standort', // Translated name
                                    validSince: '2024-03-01',
                                },
                                code: 'CTR03',
                                shortName: 'ZentrumOrtho', // Translated shortName
                                name: 'Orthopädisches Zentrum', // Translated name
                                validSince: '2024-03-01',
                            },
                        ],
                        code: 'DEPT03',
                        shortName: 'AbtOrtho', // Translated shortName
                        name: 'Abteilung für Orthopädie', // Translated name
                        validSince: '2024-03-01',
                    },
                    start: '2024-08-12T08:00:00Z',
                    end: '2024-08-20T15:00:00Z',
                },
                history: [],
            },
            patient: {
                id: 'fd4d1ade-7605-45d3-a495-c98a2bdabd11',
                locator: 'patient.fd4d1ade-7605-45d3-a495-c98a2bdabd11',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844564'],
                owner_departments: ['orthopedics'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-04-08T10:00:00Z',
                status: 'final', // Not translated
                related_patient_id: 'fd4d1ade-7605-45d3-a495-c98a2bdabd11',
                related_case_id: '80b09180-0fb5-475c-afbc-043513cee4b9',
                data: {
                    surname: 'Johannsen', // Translated surname
                    name: 'David', // No need to translate
                    gender: 'male', // Translated gender
                    birth_date: '1980-02-28',
                    valid_from: '2024-03-01',
                    valid_until: '2025-03-01',
                },
                history: [],
                i18n: {},
            },
            current_place: {
                id: '650e8400-e29b-41d4-a716-446655440111',
                related_case_id: 'related_case_id',
                locator: 'place-650e8400-e29b-41d4-a716-446655440111',
                owners: ['7edc1d64-c6f7-4b2d-8121-333cd9844573'],
                owner_departments: ['general'], // Not translated
                main_owner_job_type: 'nurse', // Not translated
                created_at: '2024-08-09T12:00:00Z',
                status: 'final', // Not translated
                related_patient_id: '80b09180-0fb5-475c-afbc-043513cee4b9',
                data: {
                    room: {
                        serviceUnit: {
                            departments: [
                                {
                                    centers: [
                                        {
                                            location: {
                                                corporation: {
                                                    code: 'CORP01',
                                                    shortName: 'CorpKurz', // Translated shortName
                                                    name: 'Konzernname', // Translated name
                                                    validSince: '2024-01-01',
                                                },
                                                code: 'LOC01',
                                                shortName: 'LocKurz', // Translated shortName
                                                name: 'Standortname', // Translated name
                                                validSince: '2024-01-01',
                                            },
                                            code: 'CTR01',
                                            shortName: 'ZentrumKurz', // Translated shortName
                                            name: 'Zentrumsname', // Translated name
                                            validSince: '2024-01-01',
                                        },
                                    ],
                                    code: 'DEPT01',
                                    shortName: 'AbtKurz', // Translated shortName
                                    name: 'Abteilungsname', // Translated name
                                    validSince: '2024-01-01',
                                },
                            ],
                            code: 'SU01',
                            shortName: 'SUKurz', // Translated shortName
                            name: 'Dienstleistungseinheit 3', // Translated name
                            validSince: '2024-01-01',
                        },
                        code: 'ROOM01',
                        shortName: 'ZimmerKurz', // Translated shortName
                        name: '201-C',
                        validSince: '2024-01-01',
                    },
                    code: 'PLACE01',
                    shortName: 'OrtKurz', // Translated shortName
                    name: 'Bett 01',
                    validSince: '2024-01-01',
                },
                history: [],
            },
            last_diagnose: dummyVC_Diagnose, // Assuming diagnosis remains unchanged
        },
        visit_record: {
            id: 'c70e8400-e29b-41d4-a716-446655440780',
            locator: 'visit-record-c70e8400-e29b-41d4-a716-446655440780',
            owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844564'],
            owner_departments: ['orthopedics'], // Not translated
            main_owner_job_type: 'doctor', // Not translated
            created_at: '2024-08-13T13:00:00Z',
            status: 'final', // Not translated
            related_patient_id: 'fd4d1ade-7605-45d3-a495-c98a2bdabd11',
            related_case_id: '80b09180-0fb5-475c-afbc-043513cee4b9',
            data: {
                note: 'Der Patient wurde erfolgreich operiert.', // Translated note
                date_and_time: '2024-08-13T13:00:00Z',
            },
            history: [
                {
                    data: {
                        note: 'Der Patient wurde erfolgreich operiert.', // Translated note
                        date_and_time: '',
                    },
                    modifiedAt: '2024-08-14T11:00:00Z',
                    modifiedBy: {
                        userId: 'dummy_user_id',
                        surname: 'Weiß', // Translated surname
                        name: 'Jakob', // Translated name
                        validSince: '2021-03-01',
                        validUntil: '2026-03-01',
                    },
                },
            ],
            i18n: {
                de: { note: 'Notizen', date_and_time: 'Datum und Zeit' },
                en: { note: 'Note', date_and_time: 'Date and time' },
            },
        },
        tasks: [
            {
                id: 'f10e8400-e29b-41d4-a716-446655441114',
                locator: 'task-f10e8400-e29b-41d4-a716-446655441114',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844564'],
                owner_departments: ['orthopedics'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-13T14:30:00Z',
                status: 'final', // Not translated
                related_patient_id: 'fd4d1ade-7605-45d3-a495-c98a2bdabd11',
                related_case_id: '80b09180-0fb5-475c-afbc-043513cee4b9',
                data: {
                    task_name: 'Rehabilitation nach der Operation', // Translated task name
                    details: 'Physiotherapiesitzungen planen und überwachen.', // Translated details
                    priority: 'high', // Translated priority
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Weiß', // Translated surname
                        name: 'Jakob', // Translated name
                        validSince: '2021-03-01',
                        validUntil: '2026-03-01',
                    },
                    goalDateOn: '2024-08-20T12:00:00Z',
                    done: false,
                    contractor: {
                        userId: 'dummy_user_id',
                        surname: 'Braun', // Translated surname
                        name: 'Thomas', // Translated name
                        validSince: '2022-02-01',
                        validUntil: '2027-02-01',
                    },
                },
                history: [
                    {
                        data: {
                            task_name: 'Rehabilitation nach der Operation', // Translated task name
                            details:
                                'Physiotherapiesitzungen planen und überwachen.', // Translated details
                            priority: 'high', // Translated priority
                            editor: {
                                userId: 'dummy_id',
                                surname: 'Weiß', // Translated surname
                                name: 'Jakob', // Translated name
                                validSince: '2021-03-01',
                                validUntil: '2026-03-01',
                            },
                            goalDateOn: '2024-08-20T12:00:00Z',
                            done: false,
                            contractor: {
                                userId: 'dummy_id',
                                surname: 'Braun', // Translated surname
                                name: 'Thomas', // Translated name
                                validSince: '2022-02-01',
                                validUntil: '2027-02-01',
                            },
                        },
                        modifiedAt: '2024-08-14T14:00:00Z',
                        modifiedBy: {
                            userId: 'dummy_user_id',
                            surname: 'Weiß', // Translated surname
                            name: 'Jakob', // Translated name
                            validSince: '2021-03-01',
                            validUntil: '2026-03-01',
                        },
                    },
                ],
                i18n: {
                    de: {
                        task_name: 'Notizen',
                        details: 'Datum und Zeit',
                        priority: 'Priorität',
                        goal_date: 'Zieldatum', // Translated
                        done: 'Erledigt', // Translated
                        notes: 'Notizen', // Translated
                        contractor: 'Auftragnehmer', // Translated
                        editor_surname: 'Nachname des Bearbeiters', // Translated
                        editor_name: 'Vorname des Bearbeiters', // Translated
                        contractor_surname: 'Nachname des Auftragnehmers', // Translated
                        contractor_name: 'Vorname des Auftragnehmers', // Translated
                    },
                    en: {
                        task_name: 'Note',
                        details: 'Date and time',
                        priority: 'Priority',
                        goal_date: 'Goal Date',
                        done: 'Done',
                        notes: 'Note',
                        contractor: 'Contractor',
                        editor_surname: 'Editor surname',
                        editor_name: 'Editor name',
                        contractor_surname: 'Contractor surname',
                        contractor_name: 'Contractor name',
                    },
                },
            },
        ],
    },
    {
        patient_details: {
            case: {
                id: 'a60e8400-e29b-41d4-a716-446655440555',
                locator: 'case.a60e8400-e29b-41d4-a716-446655440555',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844564'],
                owner_departments: ['orthopedics'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-12T12:00:00Z',
                status: 'final', // Not translated
                related_patient_id: 'fd4d1ade-7605-45d3-a495-c98a2bdabd11',
                related_case_id: '7e8bf7a6-7dca-41c8-af11-9346da764c1e',
                data: {
                    patient: {
                        surname: 'Johansson', // Translated surname
                        name: 'David', // No need to translate
                        gender: 'male', // Translated gender
                        birth_date: '1980-02-28',
                        valid_from: '2024-03-01',
                        valid_until: '2025-03-01',
                    },
                    admission_date: '2024-08-12',
                    discharge_date: '2024-08-20',
                    planned_discharge_date: '2024-08-20',
                    department: {
                        centers: [
                            {
                                location: {
                                    corporation: {
                                        code: 'CORP03',
                                        shortName: 'CorpOrtho',
                                        name: 'Orthopädische Gesellschaft', // Translated name
                                        validSince: '2024-03-01',
                                    },
                                    code: 'LOC03',
                                    shortName: 'LocOrtho',
                                    name: 'Orthopädischer Standort', // Translated name
                                    validSince: '2024-03-01',
                                },
                                code: 'CTR03',
                                shortName: 'ZentrumOrtho', // Translated shortName
                                name: 'Orthopädisches Zentrum', // Translated name
                                validSince: '2024-03-01',
                            },
                        ],
                        code: 'DEPT03',
                        shortName: 'AbtOrtho', // Translated shortName
                        name: 'Abteilung für Orthopädie', // Translated name
                        validSince: '2024-03-01',
                    },
                    start: '2024-08-12T08:00:00Z',
                    end: '2024-08-20T15:00:00Z',
                },
                history: [],
            },
            patient: {
                id: 'fd4d1ade-7605-45d3-a495-c98a2bdabd11',
                locator: 'patient.fd4d1ade-7605-45d3-a495-c98a2bdabd11',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844564'],
                owner_departments: ['orthopedics'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-04-08T10:00:00Z',
                status: 'final', // Not translated
                related_patient_id: 'fd4d1ade-7605-45d3-a495-c98a2bdabd11',
                related_case_id: '7e8bf7a6-7dca-41c8-af11-9346da764c1e',
                data: {
                    surname: 'Johansson', // Translated surname
                    name: 'David', // No need to translate
                    gender: 'male', // Translated gender
                    birth_date: '1980-02-28',
                    valid_from: '2024-03-01',
                    valid_until: '2025-03-01',
                },
                history: [],
                i18n: {},
            },
            current_place: dummyVC_Place, // No changes, as per previous pattern
            last_diagnose: dummyVC_Diagnose, // No changes, as per previous pattern
        },
        visit_record: {
            id: 'c70e8400-e29b-41d4-a716-446655440780',
            locator: 'visit-record-c70e8400-e29b-41d4-a716-446655440780',
            owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844564'],
            owner_departments: ['orthopedics'], // Not translated
            main_owner_job_type: 'doctor', // Not translated
            created_at: '2024-08-13T13:00:00Z',
            status: 'final', // Not translated
            related_patient_id: 'fd4d1ade-7605-45d3-a495-c98a2bdabd11',
            related_case_id: '7e8bf7a6-7dca-41c8-af11-9346da764c1e',
            data: {
                note: 'Patient wurde erfolgreich operiert.', // Translated note
                date_and_time: '2024-08-13T13:00:00Z',
            },
            history: [
                {
                    data: {
                        note: 'Patient wurde erfolgreich operiert.', // Translated note
                        date_and_time: '',
                    },
                    modifiedAt: '2024-08-13T13:00:00Z',
                    modifiedBy: {
                        userId: 'dummy_user_id',
                        surname: 'Weiß', // Translated surname
                        name: 'Jakob', // Translated name
                        validSince: '2021-03-01',
                        validUntil: '2026-03-01',
                    },
                },
            ],
            i18n: {
                de: { note: 'Notizen', date_and_time: 'Datum und Zeit' },
                en: { note: 'Note', date_and_time: 'Date and time' },
            },
        },
        tasks: [
            {
                id: 'f10e8400-e29b-41d4-a716-446655441114',
                locator: 'task-f10e8400-e29b-41d4-a716-446655441114',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844564'],
                owner_departments: ['orthopedics'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-13T14:30:00Z',
                status: 'final', // Not translated
                related_patient_id: 'fd4d1ade-7605-45d3-a495-c98a2bdabd11',
                related_case_id: '7e8bf7a6-7dca-41c8-af11-9346da764c1e',
                data: {
                    task_name: 'Rehabilitation nach der Operation', // Translated task name
                    details:
                        'Planung und Überwachung der Physiotherapie-Sitzungen.', // Translated details
                    priority: 'high', // Translated priority
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Weiß', // Translated surname
                        name: 'Jakob', // Translated name
                        validSince: '2021-03-01',
                        validUntil: '2026-03-01',
                    },
                    goalDateOn: '2024-08-20T12:00:00Z',
                    done: false,
                    contractor: {
                        userId: 'dummy_user_id',
                        surname: 'Braun', // Translated surname
                        name: 'Thomas', // No need to translate
                        validSince: '2022-02-01',
                        validUntil: '2027-02-01',
                    },
                },
                history: [
                    {
                        data: {
                            task_name: 'Rehabilitation nach der Operation', // Translated task name
                            details:
                                'Planung und Überwachung der Physiotherapie-Sitzungen.', // Translated details
                            priority: 'high', // Translated priority
                            editor: {
                                userId: 'dummy_id',
                                surname: 'Weiß', // Translated surname
                                name: 'Jakob', // Translated name
                                validSince: '2021-03-01',
                                validUntil: '2026-03-01',
                            },
                            goalDateOn: '2024-08-20T12:00:00Z',
                            done: false,
                            contractor: {
                                userId: 'dummy_id',
                                surname: 'Braun', // Translated surname
                                name: 'Thomas', // No need to translate
                                validSince: '2022-02-01',
                                validUntil: '2027-02-01',
                            },
                        },
                        modifiedAt: '2024-08-14T14:00:00Z',
                        modifiedBy: {
                            userId: 'dummy_user_id',
                            surname: 'Weiß', // Translated surname
                            name: 'Jakob', // Translated name
                            validSince: '2021-03-01',
                            validUntil: '2026-03-01',
                        },
                    },
                ],
                i18n: {
                    de: {
                        task_name: 'Aufgabe',
                        details: 'Details',
                        priority: 'Priorität',
                        goal_date: 'Zieldatum',
                        done: 'Erledigt',
                        notes: 'Notizen',
                        contractor: 'Auftragnehmer',
                        editor_surname: 'Nachname des Bearbeiters',
                        editor_name: 'Vorname des Bearbeiters',
                        contractor_surname: 'Nachname des Auftragnehmers',
                        contractor_name: 'Vorname des Auftragnehmers',
                    },
                    en: {
                        task_name: 'Task name',
                        details: 'Details',
                        priority: 'Priority',
                        goal_date: 'Goal Date',
                        done: 'Done',
                        notes: 'Note',
                        contractor: 'Contractor',
                        editor_surname: 'Editor surname',
                        editor_name: 'Editor name',
                        contractor_surname: 'Contractor surname',
                        contractor_name: 'Contractor name',
                    },
                },
            },
        ],
    },
    {
        patient_details: {
            case: {
                id: 'c70e8400-e29b-41d4-a716-446655440881',
                locator: 'case.c70e8400-e29b-41d4-a716-446655440881',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844565'],
                owner_departments: ['cardiology'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-15T12:00:00Z',
                status: 'final', // Not translated
                related_patient_id: 'c4d5e6f7-88g9-4h0i-j1kl-2m34n567o890',
                related_case_id: '50ade231-58f5-42e6-8880-387f15d90945',
                data: {
                    patient: {
                        surname: 'Martínez', // Translated surname
                        name: 'Sophia', // No need to translate
                        gender: 'female', // Translated gender
                        birth_date: '1980-11-07',
                        valid_from: '2024-03-01',
                        valid_until: '2025-03-01',
                    },
                    admission_date: '2024-08-15',
                    discharge_date: '2024-08-22',
                    planned_discharge_date: '2024-08-22',
                    department: {
                        centers: [
                            {
                                location: {
                                    corporation: {
                                        code: 'CORP04',
                                        shortName: 'CorpCardio',
                                        name: 'Kardiologie Gesellschaft', // Translated name
                                        validSince: '2024-03-01',
                                    },
                                    code: 'LOC04',
                                    shortName: 'LocCardio',
                                    name: 'Kardiologie Standort', // Translated name
                                    validSince: '2024-03-01',
                                },
                                code: 'CTR04',
                                shortName: 'ZentrumCardio', // Translated shortName
                                name: 'Kardiologie Zentrum', // Translated name
                                validSince: '2024-03-01',
                            },
                        ],
                        code: 'DEPT04',
                        shortName: 'AbtCardio', // Translated shortName
                        name: 'Abteilung für Kardiologie', // Translated name
                        validSince: '2024-03-01',
                    },
                    start: '2024-08-15T08:00:00Z',
                    end: '2024-08-22T15:00:00Z',
                },
                history: [],
            },
            patient: {
                id: 'c4d5e6f7-88g9-4h0i-j1kl-2m34n567o890',
                locator: 'patient.c4d5e6f7-88g9-4h0i-j1kl-2m34n567o890',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844565'],
                owner_departments: ['cardiology'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-04-09T10:00:00Z',
                status: 'final', // Not translated
                related_patient_id: 'c4d5e6f7-88g9-4h0i-j1kl-2m34n567o890',
                related_case_id: 'ff4f9577-7198-4ebf-adfe-03205a962439',
                data: {
                    surname: 'Martínez', // Translated surname
                    name: 'Sophia', // No need to translate
                    gender: 'female', // Translated gender
                    birth_date: '1980-11-07',
                    valid_from: '2024-03-01',
                    valid_until: '2025-03-01',
                },
                history: [],
                i18n: {},
            },
            current_place: dummyVC_Place, // No changes, as per previous pattern
            last_diagnose: dummyVC_Diagnose, // No changes, as per previous pattern
        },
        visit_record: {
            id: 'c70e8400-e29b-41d4-a716-446655440882',
            locator: 'visit-record-c70e8400-e29b-41d4-a716-446655440882',
            owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844565'],
            owner_departments: ['cardiology'], // Not translated
            main_owner_job_type: 'doctor', // Not translated
            created_at: '2024-08-16T13:00:00Z',
            status: 'final', // Not translated
            related_patient_id: 'c4d5e6f7-88g9-4h0i-j1kl-2m34n567o890',
            related_case_id: '50ade231-58f5-42e6-8880-387f15d90945',
            data: {
                note: 'Der Zustand des Patienten ist mit der aktuellen Behandlung stabil.', // Translated note
                date_and_time: '2024-08-16T13:00:00Z',
            },
            history: [
                {
                    data: {
                        note: 'Der Zustand des Patienten ist mit der aktuellen Behandlung stabil.', // Translated note
                        date_and_time: '',
                    },
                    modifiedAt: '2024-08-16T13:00:00Z',
                    modifiedBy: {
                        userId: 'dummy_user_id',
                        surname: 'Davis', // No need to translate
                        name: 'Michael', // No need to translate
                        validSince: '2021-04-01',
                        validUntil: '2026-04-01',
                    },
                },
            ],
            i18n: {
                de: { note: 'Notizen', date_and_time: 'Datum und Zeit' },
                en: { note: 'Note', date_and_time: 'Date and time' },
            },
        },
        tasks: [
            {
                id: 'f10e8400-e29b-41d4-a716-446655441115',
                locator: 'task-f10e8400-e29b-41d4-a716-446655441115',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844565'],
                owner_departments: ['cardiology'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-16T14:30:00Z',
                status: 'final', // Not translated
                related_patient_id: 'c4d5e6f7-88g9-4h0i-j1kl-2m34n567o890',
                related_case_id: '50ade231-58f5-42e6-8880-387f15d90945',
                data: {
                    task_name: 'Dialyse-Plan', // Translated task name
                    details:
                        'Stellen Sie sicher, dass der Patient die Dialyse gemäß dem Zeitplan erhält.', // Translated details
                    priority: 'high', // Translated priority
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Davis', // No need to translate
                        name: 'Michael', // No need to translate
                        validSince: '2021-04-01',
                        validUntil: '2026-04-01',
                    },
                    goalDateOn: '2024-08-20T12:00:00Z',
                    done: false,
                    contractor: {
                        userId: 'dummy_user_id',
                        surname: 'Miller', // No need to translate
                        name: 'John', // No need to translate
                        validSince: '2022-03-01',
                        validUntil: '2027-03-01',
                    },
                },
                history: [
                    {
                        data: {
                            task_name: 'Dialyse-Plan', // Translated task name
                            details:
                                'Stellen Sie sicher, dass der Patient die Dialyse gemäß dem Zeitplan erhält.', // Translated details
                            priority: 'high', // Translated priority
                            editor: {
                                userId: 'dummy_id',
                                surname: 'Davis', // No need to translate
                                name: 'Michael', // No need to translate
                                validSince: '2021-04-01',
                                validUntil: '2026-04-01',
                            },
                            goalDateOn: '2024-08-20T12:00:00Z',
                            done: false,
                            contractor: {
                                userId: 'dummy_id',
                                surname: 'Miller', // No need to translate
                                name: 'John', // No need to translate
                                validSince: '2022-03-01',
                                validUntil: '2027-03-01',
                            },
                        },
                        modifiedAt: '2024-08-17T14:00:00Z',
                        modifiedBy: {
                            userId: 'dummy_user_id',
                            surname: 'Davis', // No need to translate
                            name: 'Michael', // No need to translate
                            validSince: '2021-04-01',
                            validUntil: '2026-04-01',
                        },
                    },
                ],
                i18n: {
                    de: {
                        task_name: 'Aufgabe',
                        details: 'Details',
                        priority: 'Priorität',
                        goal_date: 'Zieldatum',
                        done: 'Erledigt',
                        notes: 'Notiz',
                        contractor: 'Auftragnehmer',
                        editor_surname: 'Nachname des Bearbeiters',
                        editor_name: 'Vorname des Bearbeiters',
                        contractor_surname: 'Nachname des Auftragnehmers',
                        contractor_name: 'Vorname des Auftragnehmers',
                    },
                    en: {
                        task_name: 'Task name',
                        details: 'Details',
                        priority: 'Priority',
                        goal_date: 'Goal Date',
                        done: 'Done',
                        notes: 'Note',
                        contractor: 'Contractor',
                        editor_surname: 'Editor surname',
                        editor_name: 'Editor name',
                        contractor_surname: 'Contractor surname',
                        contractor_name: 'Contractor name',
                    },
                },
            },
        ],
    },
    {
        patient_details: {
            case: {
                id: 'd70e8400-e29b-41d4-a716-446655440992',
                locator: 'case.d70e8400-e29b-41d4-a716-446655440992',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844566'],
                owner_departments: ['general_medicine'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-18T12:00:00Z',
                status: 'final', // Not translated
                related_patient_id: 'd7e8f9fa-0b1c-2d3e-4f5g-6h7i8j9k0l1m',
                related_case_id: '6166a122-f7c2-4deb-add2-f6dfd3dc93f8',
                data: {
                    patient: {
                        surname: 'Garcia', // No need to translate
                        name: 'James', // No need to translate
                        gender: 'male', // Translated gender
                        birth_date: '1980-03-05',
                        valid_from: '2024-04-01',
                        valid_until: '2025-04-01',
                    },
                    admission_date: '2024-08-18',
                    discharge_date: '2024-08-24',
                    planned_discharge_date: '2024-08-24',
                    department: {
                        centers: [
                            {
                                location: {
                                    corporation: {
                                        code: 'CORP05',
                                        shortName: 'CorpGenMed',
                                        name: 'Allgemeinmedizinische Gesellschaft', // Translated name
                                        validSince: '2024-04-01',
                                    },
                                    code: 'LOC05',
                                    shortName: 'LocGenMed',
                                    name: 'Allgemeinmedizinischer Standort', // Translated name
                                    validSince: '2024-04-01',
                                },
                                code: 'CTR05',
                                shortName: 'ZentrumGenMed', // Translated shortName
                                name: 'Allgemeinmedizinisches Zentrum', // Translated name
                                validSince: '2024-04-01',
                            },
                        ],
                        code: 'DEPT05',
                        shortName: 'AbtGenMed', // Translated shortName
                        name: 'Abteilung für Allgemeinmedizin', // Translated name
                        validSince: '2024-04-01',
                    },
                    start: '2024-08-18T08:00:00Z',
                    end: '2024-08-24T15:00:00Z',
                },
                history: [],
            },
            patient: {
                id: 'd7e8f9fa-0b1c-2d3e-4f5g-6h7i8j9k0l1m',
                locator: 'patient.d7e8f9fa-0b1c-2d3e-4f5g-6h7i8j9k0l1m',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844566'],
                owner_departments: ['general_medicine'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-04-10T10:00:00Z',
                status: 'final', // Not translated
                related_patient_id: 'd7e8f9fa-0b1c-2d3e-4f5g-6h7i8j9k0l1m',
                related_case_id: '6166a122-f7c2-4deb-add2-f6dfd3dc93f8',
                data: {
                    surname: 'Garcia', // No need to translate
                    name: 'James', // No need to translate
                    gender: 'male', // Translated gender
                    birth_date: '1980-03-05',
                    valid_from: '2024-04-01',
                    valid_until: '2025-04-01',
                },
                history: [],
                i18n: {},
            },
            current_place: dummyVC_Place, // No changes as per previous example
            last_diagnose: dummyVC_Diagnose, // No changes as per previous example
        },
        visit_record: {
            id: 'e70e8400-e29b-41d4-a716-446655440993',
            locator: 'visit-record-e70e8400-e29b-41d4-a716-446655440993',
            owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844566'],
            owner_departments: ['general_medicine'], // Not translated
            main_owner_job_type: 'doctor', // Not translated
            created_at: '2024-08-19T13:00:00Z',
            status: 'final', // Not translated
            related_patient_id: 'd7e8f9fa-0b1c-2d3e-4f5g-6h7i8j9k0l1m',
            related_case_id: '6166a122-f7c2-4deb-add2-f6dfd3dc93f8',
            data: {
                note: 'Der Patient erholt sich gut von der Fraktur.', // Translated note
                date_and_time: '2024-08-19T13:00:00Z',
            },
            history: [
                {
                    data: {
                        note: 'Der Patient erholt sich gut von der Fraktur.', // Translated note
                        date_and_time: '',
                    },
                    modifiedAt: '2024-08-19T13:00:00Z',
                    modifiedBy: {
                        userId: 'dummy_user_id',
                        surname: 'Lopez', // No need to translate
                        name: 'Maria', // No need to translate
                        validSince: '2021-05-01',
                        validUntil: '2026-05-01',
                    },
                },
            ],
            i18n: {
                de: { note: 'Notizen', date_and_time: 'Datum und Zeit' },
                en: { note: 'Note', date_and_time: 'Date and time' },
            },
        },
        tasks: [
            {
                id: 'g10e8400-e29b-41d4-a716-446655441116',
                locator: 'task-g10e8400-e29b-41d4-a716-446655441116',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844566'],
                owner_departments: ['general_medicine'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-19T14:30:00Z',
                status: 'final', // Not translated
                related_patient_id: 'd7e8f9fa-0b1c-2d3e-4f5g-6h7i8j9k0l1m',
                related_case_id: '6166a122-f7c2-4deb-add2-f6dfd3dc93f8',
                data: {
                    task_name: 'Nachuntersuchung der Fraktur', // Translated task name
                    details:
                        'Stellen Sie sicher, dass die Fraktur des Patienten wie erwartet heilt.', // Translated details
                    priority: 'high', // Translated priority
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Lopez', // No need to translate
                        name: 'Maria', // No need to translate
                        validSince: '2021-05-01',
                        validUntil: '2026-05-01',
                    },
                    goalDateOn: '2024-08-23T12:00:00Z',
                    done: false,
                    contractor: {
                        userId: 'dummy_user_id',
                        surname: 'Martin', // No need to translate
                        name: 'Emily', // No need to translate
                        validSince: '2022-04-01',
                        validUntil: '2027-04-01',
                    },
                },
                history: [
                    {
                        data: {
                            task_name: 'Nachuntersuchung der Fraktur', // Translated task name
                            details:
                                'Stellen Sie sicher, dass die Fraktur des Patienten wie erwartet heilt.', // Translated details
                            priority: 'high', // Translated priority
                            editor: {
                                userId: 'dummy_id',
                                surname: 'Lopez', // No need to translate
                                name: 'Maria', // No need to translate
                                validSince: '2021-05-01',
                                validUntil: '2026-05-01',
                            },
                            goalDateOn: '2024-08-23T12:00:00Z',
                            done: false,
                            contractor: {
                                userId: 'dummy_id',
                                surname: 'Martin', // No need to translate
                                name: 'Emily', // No need to translate
                                validSince: '2022-04-01',
                                validUntil: '2027-04-01',
                            },
                        },
                        modifiedAt: '2024-08-20T14:00:00Z',
                        modifiedBy: {
                            userId: 'dummy_user_id',
                            surname: 'Lopez', // No need to translate
                            name: 'Maria', // No need to translate
                            validSince: '2021-05-01',
                            validUntil: '2026-05-01',
                        },
                    },
                ],
                i18n: {
                    de: {
                        task_name: 'Notizen',
                        details: 'Datum und Zeit',
                        priority: 'Priorität',
                        goal_date: 'Zieldatum',
                        done: 'Erledigt',
                        notes: 'Notiz',
                        contractor: 'Auftragnehmer',
                        editor_surname: 'Nachname des Bearbeiters',
                        editor_name: 'Vorname des Bearbeiters',
                        contractor_surname: 'Nachname des Auftragnehmers',
                        contractor_name: 'Vorname des Auftragnehmers',
                    },
                    en: {
                        task_name: 'Task name',
                        details: 'Details',
                        priority: 'Priority',
                        goal_date: 'Goal Date',
                        done: 'Done',
                        notes: 'Note',
                        contractor: 'Contractor',
                        editor_surname: 'Editor surname',
                        editor_name: 'Editor name',
                        contractor_surname: 'Contractor surname',
                        contractor_name: 'Contractor name',
                    },
                },
            },
        ],
    },
    {
        patient_details: {
            case: {
                id: 'f70e8400-e29b-41d4-a716-446655440994',
                locator: 'case.f70e8400-e29b-41d4-a716-446655440994',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844567'],
                owner_departments: ['pediatrics'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-20T12:00:00Z',
                status: 'final', // Not translated
                related_patient_id: 'e8f9g0h1-2i3j-4k5l-6m7n-8o9p0q1r2s3t',
                related_case_id: 'c85fe2e0-c9f0-4de3-9374-a75dd583d19c',
                data: {
                    patient: {
                        surname: 'Wilson', // No need to translate
                        name: 'Linda', // No need to translate
                        gender: 'female', // Translated gender
                        birth_date: '1980-09-29',
                        valid_from: '2024-05-01',
                        valid_until: '2025-05-01',
                    },
                    admission_date: '2024-08-20',
                    discharge_date: '2024-08-26',
                    planned_discharge_date: '2024-08-26',
                    department: {
                        centers: [
                            {
                                location: {
                                    corporation: {
                                        code: 'CORP06',
                                        shortName: 'CorpPediatrics',
                                        name: 'Pädiatrie-Gesellschaft', // Translated name
                                        validSince: '2024-05-01',
                                    },
                                    code: 'LOC06',
                                    shortName: 'LocPediatrics',
                                    name: 'Pädiatrischer Standort', // Translated name
                                    validSince: '2024-05-01',
                                },
                                code: 'CTR06',
                                shortName: 'ZentrumPädiatrie', // Translated shortName
                                name: 'Pädiatrisches Zentrum', // Translated name
                                validSince: '2024-05-01',
                            },
                        ],
                        code: 'DEPT06',
                        shortName: 'AbtPädiatrie', // Translated shortName
                        name: 'Pädiatrische Abteilung', // Translated name
                        validSince: '2024-05-01',
                    },
                    start: '2024-08-20T08:00:00Z',
                    end: '2024-08-26T15:00:00Z',
                },
                history: [],
            },
            patient: {
                id: 'e8f9g0h1-2i3j-4k5l-6m7n-8o9p0q1r2s3t',
                locator: 'patient.e8f9g0h1-2i3j-4k5l-6m7n-8o9p0q1r2s3t',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844567'],
                owner_departments: ['pediatrics'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-04-11T10:00:00Z',
                status: 'final', // Not translated
                related_patient_id: 'e8f9g0h1-2i3j-4k5l-6m7n-8o9p0q1r2s3t',
                related_case_id: 'related_case_id_005',
                data: {
                    surname: 'Wilson', // No need to translate
                    name: 'Linda', // No need to translate
                    gender: 'female', // Translated gender
                    birth_date: '1980-09-29',
                    valid_from: '2024-05-01',
                    valid_until: '2025-05-01',
                },
                history: [],
                i18n: {},
            },
            current_place: dummyVC_Place, // No changes as per previous examples
            last_diagnose: dummyVC_Diagnose, // No changes as per previous examples
        },
        visit_record: {
            id: 'f70e8400-e29b-41d4-a716-446655440995',
            locator: 'visit-record-f70e8400-e29b-41d4-a716-446655440995',
            owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844567'],
            owner_departments: ['pediatrics'], // Not translated
            main_owner_job_type: 'doctor', // Not translated
            created_at: '2024-08-21T13:00:00Z',
            status: 'final', // Not translated
            related_patient_id: 'e8f9g0h1-2i3j-4k5l-6m7n-8o9p0q1r2s3t',
            related_case_id: 'c85fe2e0-c9f0-4de3-9374-a75dd583d19c',
            data: {
                note: 'Der Patient hat gut auf die Flüssigkeitstherapie angesprochen.', // Translated note
                date_and_time: '2024-08-21T13:00:00Z',
            },
            history: [
                {
                    data: {
                        note: 'Der Patient hat gut auf die Flüssigkeitstherapie angesprochen.', // Translated note
                        date_and_time: '',
                    },
                    modifiedAt: '2024-08-21T13:00:00Z',
                    modifiedBy: {
                        userId: 'dummy_user_id',
                        surname: 'Johnson', // No need to translate
                        name: 'David', // No need to translate
                        validSince: '2021-06-01',
                        validUntil: '2026-06-01',
                    },
                },
            ],
            i18n: {
                de: { note: 'Notizen', date_and_time: 'Datum und Zeit' },
                en: { note: 'Note', date_and_time: 'Date and time' },
            },
        },
        tasks: [
            {
                id: 'h10e8400-e29b-41d4-a716-446655441117',
                locator: 'task-h10e8400-e29b-41d4-a716-446655441117',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844567'],
                owner_departments: ['pediatrics'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-21T14:30:00Z',
                status: 'final', // Not translated
                related_patient_id: 'e8f9g0h1-2i3j-4k5l-6m7n-8o9p0q1r2s3t',
                related_case_id: 'c85fe2e0-c9f0-4de3-9374-a75dd583d19c',
                data: {
                    task_name: 'Überwachung der Flüssigkeitstherapie', // Translated task name
                    details:
                        'Überwachung der Reaktion des Patienten auf die Flüssigkeitstherapie.', // Translated details
                    priority: 'high', // Translated priority
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Johnson', // No need to translate
                        name: 'David', // No need to translate
                        validSince: '2021-06-01',
                        validUntil: '2026-06-01',
                    },
                    goalDateOn: '2024-08-25T12:00:00Z',
                    done: false,
                    contractor: {
                        userId: 'dummy_user_id',
                        surname: 'Harris', // No need to translate
                        name: 'Anna', // No need to translate
                        validSince: '2022-05-01',
                        validUntil: '2027-05-01',
                    },
                },
                history: [
                    {
                        data: {
                            task_name: 'Überwachung der Flüssigkeitstherapie', // Translated task name
                            details:
                                'Überwachung der Reaktion des Patienten auf die Flüssigkeitstherapie.', // Translated details
                            priority: 'high', // Translated priority
                            editor: {
                                userId: 'dummy_id',
                                surname: 'Johnson', // No need to translate
                                name: 'David', // No need to translate
                                validSince: '2021-06-01',
                                validUntil: '2026-06-01',
                            },
                            goalDateOn: '2024-08-25T12:00:00Z',
                            done: false,
                            contractor: {
                                userId: 'dummy_id',
                                surname: 'Harris', // No need to translate
                                name: 'Anna', // No need to translate
                                validSince: '2022-05-01',
                                validUntil: '2027-05-01',
                            },
                        },
                        modifiedAt: '2024-08-22T14:00:00Z',
                        modifiedBy: {
                            userId: 'dummy_user_id',
                            surname: 'Johnson', // No need to translate
                            name: 'David', // No need to translate
                            validSince: '2021-06-01',
                            validUntil: '2026-06-01',
                        },
                    },
                ],
                i18n: {
                    de: {
                        task_name: 'Überwachung der Flüssigkeitstherapie',
                        details:
                            'Überwachung der Reaktion des Patienten auf die Flüssigkeitstherapie.',
                        priority: 'high',
                        goal_date: 'Zieldatum',
                        done: 'Erledigt',
                        notes: 'Notizen',
                        contractor: 'Auftragnehmer',
                        editor_surname: 'Nachname des Bearbeiters',
                        editor_name: 'Vorname des Bearbeiters',
                        contractor_surname: 'Nachname des Auftragnehmers',
                        contractor_name: 'Vorname des Auftragnehmers',
                    },
                    en: {
                        task_name: 'Fluid Therapy Monitoring',
                        details: 'Monitor patient’s response to fluid therapy.',
                        priority: 'Priority',
                        goal_date: 'Goal Date',
                        done: 'Done',
                        notes: 'Note',
                        contractor: 'Contractor',
                        editor_surname: 'Editor surname',
                        editor_name: 'Editor name',
                        contractor_surname: 'Contractor surname',
                        contractor_name: 'Contractor name',
                    },
                },
            },
        ],
    },
    {
        patient_details: {
            case: {
                id: 'g80e8400-e29b-41d4-a716-446655440996',
                locator: 'case.g80e8400-e29b-41d4-a716-446655440996',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844568'],
                owner_departments: ['dermatology'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-22T12:00:00Z',
                status: 'final', // Not translated
                related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
                related_case_id: 'ff4f9577-7198-4ebf-adfe-03205a962439',
                data: {
                    patient: {
                        surname: 'Anderson', // No need to translate
                        name: 'Charles', // No need to translate
                        gender: 'male', // Translated gender
                        birth_date: '1980-10-24',
                        valid_from: '2024-05-01',
                        valid_until: '2025-05-01',
                    },
                    admission_date: '2024-08-22',
                    discharge_date: '2024-08-28',
                    planned_discharge_date: '2024-08-28',
                    department: {
                        centers: [
                            {
                                location: {
                                    corporation: {
                                        code: 'CORP07',
                                        shortName: 'CorpDerm',
                                        name: 'Dermatologische Gesellschaft', // Translated name
                                        validSince: '2024-05-01',
                                    },
                                    code: 'LOC07',
                                    shortName: 'LocDerm',
                                    name: 'Dermatologischer Standort', // Translated name
                                    validSince: '2024-05-01',
                                },
                                code: 'CTR07',
                                shortName: 'ZentrumDerm', // Translated shortName
                                name: 'Dermatologisches Zentrum', // Translated name
                                validSince: '2024-05-01',
                            },
                        ],
                        code: 'DEPT07',
                        shortName: 'AbtDerm', // Translated shortName
                        name: 'Dermatologische Abteilung', // Translated name
                        validSince: '2024-05-01',
                    },
                    start: '2024-08-22T08:00:00Z',
                    end: '2024-08-28T15:00:00Z',
                },
                history: [],
            },
            patient: {
                id: '550e8400-e29b-41d4-a716-446655440000',
                locator: 'patient.550e8400-e29b-41d4-a716-446655440000',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844568'],
                owner_departments: ['dermatology'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-04-12T10:00:00Z',
                status: 'final', // Not translated
                related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
                related_case_id: 'related_case_id_006',
                data: {
                    surname: 'Anderson', // No need to translate
                    name: 'Charles', // No need to translate
                    gender: 'male', // Translated gender
                    birth_date: '1980-10-24',
                    valid_from: '2024-05-01',
                    valid_until: '2025-05-01',
                },
                history: [],
                i18n: {},
            },
            current_place: dummyVC_Place, // No changes as per previous examples
            last_diagnose: dummyVC_Diagnose, // No changes as per previous examples
        },
        visit_record: {
            id: 'g80e8400-e29b-41d4-a716-446655440997',
            locator: 'visit-record-g80e8400-e29b-41d4-a716-446655440997',
            owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844568'],
            owner_departments: ['dermatology'], // Not translated
            main_owner_job_type: 'doctor', // Not translated
            created_at: '2024-08-23T13:00:00Z',
            status: 'final', // Not translated
            related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
            related_case_id: 'ff4f9577-7198-4ebf-adfe-03205a962439',
            data: {
                note: 'Der Patient spricht gut auf die Behandlung an. Fortsetzen mit der aktuellen Therapie.', // Translated note
                date_and_time: '2024-08-23T13:00:00Z',
            },
            history: [
                {
                    data: {
                        note: 'Der Patient spricht gut auf die Behandlung an. Fortsetzen mit der aktuellen Therapie.', // Translated note
                        date_and_time: '2024-08-23T13:00:00Z',
                    },
                    modifiedAt: '2024-08-23T14:00:00Z',
                    modifiedBy: {
                        userId: 'dummy_user_id',
                        surname: 'Morris', // No need to translate
                        name: 'Jessica', // No need to translate
                        validSince: '2021-07-01',
                        validUntil: '2026-07-01',
                    },
                },
                {
                    data: {
                        note: 'Der Patient spricht gut auf die Behandlung an.', // Translated note
                        date_and_time: '2024-08-23T13:00:00Z',
                    },
                    modifiedAt: '2024-08-23T16:00:00Z',
                    modifiedBy: {
                        userId: 'dummy_user_id',
                        surname: 'Morris', // No need to translate
                        name: 'Jessica', // No need to translate
                        validSince: '2021-07-01',
                        validUntil: '2026-07-01',
                    },
                },
            ],
            i18n: {
                de: { note: 'Notizen', date_and_time: 'Datum und Zeit' },
                en: { note: 'Note', date_and_time: 'Date and time' },
            },
        },
        tasks: [
            {
                id: 'i10e8400-e29b-41d4-a716-446655441118',
                locator: 'task-i10e8400-e29b-41d4-a716-446655441118',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844568'],
                owner_departments: ['dermatology'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-23T14:30:00Z',
                status: 'final', // Not translated
                related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
                related_case_id: 'ff4f9577-7198-4ebf-adfe-03205a962439',
                data: {
                    task_name: 'Auftragen von topischen Behandlungen', // Translated task name
                    details:
                        'Anwendung und Überwachung der Wirksamkeit topischer Behandlungen.', // Translated details
                    priority: 'high', // Translated priority
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Morris', // No need to translate
                        name: 'Jessica', // No need to translate
                        validSince: '2021-07-01',
                        validUntil: '2026-07-01',
                    },
                    goalDateOn: '2024-08-27T12:00:00Z',
                    done: false,
                    contractor: {
                        userId: 'dummy_user_id',
                        surname: 'Harris', // No need to translate
                        name: 'Anna', // No need to translate
                        validSince: '2022-05-01',
                        validUntil: '2027-05-01',
                    },
                },
                history: [
                    {
                        data: {
                            task_name: 'Auftragen von topischen Behandlungen', // Translated task name
                            details:
                                'Anwendung und Überwachung der Wirksamkeit topischer Behandlungen.', // Translated details
                            priority: 'high', // Translated priority
                            editor: {
                                userId: 'dummy_id',
                                surname: 'Morris', // No need to translate
                                name: 'Jessica', // No need to translate
                                validSince: '2021-07-01',
                                validUntil: '2026-07-01',
                            },
                            goalDateOn: '2024-08-27T12:00:00Z',
                            done: false,
                            contractor: {
                                userId: 'dummy_id',
                                surname: 'Harris', // No need to translate
                                name: 'Anna', // No need to translate
                                validSince: '2022-05-01',
                                validUntil: '2027-05-01',
                            },
                        },
                        modifiedAt: '2024-08-24T14:00:00Z',
                        modifiedBy: {
                            userId: 'dummy_user_id',
                            surname: 'Morris', // No need to translate
                            name: 'Jessica', // No need to translate
                            validSince: '2021-07-01',
                            validUntil: '2026-07-01',
                        },
                    },
                ],
                i18n: {
                    de: {
                        task_name: 'Auftragen von topischen Behandlungen',
                        details:
                            'Anwendung und Überwachung der Wirksamkeit topischer Behandlungen.',
                        priority: 'high',
                        goal_date: 'Zieldatum',
                        done: 'Erledigt',
                        notes: 'Notizen',
                        contractor: 'Auftragnehmer',
                        editor_surname: 'Nachname des Bearbeiters',
                        editor_name: 'Vorname des Bearbeiters',
                        contractor_surname: 'Nachname des Auftragnehmers',
                        contractor_name: 'Vorname des Auftragnehmers',
                    },
                    en: {
                        task_name: 'Topical Treatment Application',
                        details:
                            'Apply and monitor effectiveness of topical treatments.',
                        priority: 'high',
                        goal_date: 'Goal Date',
                        done: 'Done',
                        notes: 'Note',
                        contractor: 'Contractor',
                        editor_surname: 'Editor surname',
                        editor_name: 'Editor name',
                        contractor_surname: 'Contractor surname',
                        contractor_name: 'Contractor name',
                    },
                },
            },
            {
                id: 'f10e8400-e29b-41d4-a716-446655441111',
                locator: 'task-f10e8400-e29b-41d4-a716-446655441111',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
                owner_departments: ['general_medicine'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-09T14:30:00Z',
                status: 'final', // Not translated
                related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
                related_case_id: 'ff4f9577-7198-4ebf-adfe-03205a962439',
                data: {
                    task_name: 'Überwachung der Vitalparameter', // Translated task name
                    details:
                        'Überwachung der Vitalparameter alle 4 Stunden aufgrund von Verdacht auf Meningitis.', // Translated details
                    priority: 'high', // Translated priority
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Müller', // No need to translate
                        name: 'Erika', // No need to translate
                        validSince: '2020-01-01',
                        validUntil: '2025-01-01',
                    },
                    goalDateOn: '2024-04-13T12:00:00Z',
                    done: true,
                    contractor: {
                        userId: 'dummy_user_id',
                        surname: 'Schmidt', // No need to translate
                        name: 'Hans', // No need to translate
                        validSince: '2021-01-01',
                        validUntil: '2026-01-01',
                    },
                },
                history: [
                    {
                        data: {
                            task_name: 'Überwachung der Vitalparameter', // Translated task name
                            details:
                                'Überwachung der Vitalparameter alle 4 Stunden aufgrund von Verdacht auf Meningitis.', // Translated details
                            priority: 'high', // Translated priority
                            editor: {
                                userId: 'dummy_id',
                                surname: 'Müller', // No need to translate
                                name: 'Erika', // No need to translate
                                validSince: '2020-01-01',
                                validUntil: '2025-01-01',
                            },
                            goalDateOn: '2024-04-13T12:00:00Z',
                            done: true,
                            contractor: {
                                userId: 'dummy_id',
                                surname: 'Schmidt', // No need to translate
                                name: 'Hans', // No need to translate
                                validSince: '2021-01-01',
                                validUntil: '2026-01-01',
                            },
                        },
                        modifiedAt: '2024-04-13T14:00:00Z',
                        modifiedBy: {
                            userId: 'dummy_user_id',
                            surname: 'Müller', // No need to translate
                            name: 'Erika', // No need to translate
                            validSince: '2020-01-01',
                            validUntil: '2025-01-01',
                        },
                    },
                    {
                        data: {
                            task_name: 'Überwachung der Vitalparameter', // Translated task name
                            details:
                                'Überwachung der Vitalparameter alle 4 Stunden aufgrund von Verdacht auf Meningitis.', // Translated details
                            priority: 'high', // Translated priority
                            editor: {
                                userId: 'dummy_id',
                                surname: 'Müller', // No need to translate
                                name: 'Erika', // No need to translate
                                validSince: '2020-01-01',
                                validUntil: '2025-01-01',
                            },
                            goalDateOn: '2024-04-13T12:00:00Z',
                            done: true,
                            contractor: {
                                userId: 'dummy_id',
                                surname: 'Schmidt', // No need to translate
                                name: 'Hans', // No need to translate
                                validSince: '2021-01-01',
                                validUntil: '2026-01-01',
                            },
                        },
                        modifiedAt: '2024-04-14T14:00:00Z',
                        modifiedBy: {
                            userId: 'dummy_user_id',
                            surname: 'Müller', // No need to translate
                            name: 'Erika', // No need to translate
                            validSince: '2020-01-01',
                            validUntil: '2025-01-01',
                        },
                    },
                ],
                i18n: {
                    de: {
                        task_name: 'Überwachung der Vitalparameter',
                        details:
                            'Überwachung der Vitalparameter alle 4 Stunden aufgrund von Verdacht auf Meningitis.',
                        priority: 'high',
                        goal_date: 'Zieldatum',
                        done: 'Erledigt',
                        notes: 'Notizen',
                        contractor: 'Auftragnehmer',
                        editor_surname: 'Nachname des Bearbeiters',
                        editor_name: 'Vorname des Bearbeiters',
                        contractor_surname: 'Nachname des Auftragnehmers',
                        contractor_name: 'Vorname des Auftragnehmers',
                    },
                    en: {
                        task_name: 'Vital Signs Monitoring',
                        details:
                            'Monitor vital signs every 4 hours due to suspected meningitis.',
                        priority: 'high',
                        goal_date: 'Goal Date',
                        done: 'Done',
                        notes: 'Note',
                        contractor: 'Contractor',
                        editor_surname: 'Editor surname',
                        editor_name: 'Editor name',
                        contractor_surname: 'Contractor surname',
                        contractor_name: 'Contractor name',
                    },
                },
            },
        ],
    },
];

export const dummyVC_LabReport: VC_LabReport = {
    id: '7660ea17-7dfe-49bf-8a28-a9bb6bdf86d1',
    related_case_id: '850e8400-e29b-41d4-a716-446655440333',
    locator: 'case.record.lab.850e8400-e29b-41d4-a716-446655440333',
    owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
    owner_departments: ['cardiology'],
    main_owner_job_type: 'doctor',
    created_at: '2024-08-09T12:00:00Z',
    status: 'final',
    related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
    data: {
        results: [
            {
                id: 20928,
                modified_at: '2024-09-04 13:56:25.818046+00:00',
                modified_by: 'oru-import',
                result_groups: 'H\u00e4ma, Chemie, Endo',
                received_date_time: '2024-09-04 13:56:25.818189+00:00',
                observation_date_time: '2024-09-04 13:56:25.818215+00:00',
                reported_date_time: '2024-09-04 13:56:25.818240+00:00',
                filler_order_number: '37484878',
                placer_order_number: '37484878',
                order_control: 'RE',
                verified_by: 'LABOR',
                groups: [
                    {
                        text_short: 'H\u00e4ma',
                        text_long: 'H\u00e4matologie',
                        icon_name: '',
                        color: 'rot',
                        sort_order: 20,
                        values: [
                            {
                                value: '8.0',
                                limits: '7,4 - 9,9',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'hb',
                                    short_text: 'H\u00e4moglobin',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'mmol/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '0.39',
                                limits: '0,35 - 0,47',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'hk',
                                    short_text: 'H\u00e4matokrit',
                                    long_text: '',
                                },
                                unit: {
                                    code: '',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '5.07',
                                limits: '3,85 - 5,2',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ery',
                                    short_text: 'Erythrozyten',
                                    long_text: '',
                                },
                                unit: {
                                    code: '10E12/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '77.3',
                                limits: '81 - 96',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'mcv',
                                    short_text: 'MCV',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'fl',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '1.58',
                                limits: '1,55 - 1,9',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'mch',
                                    short_text: 'MCH',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'fmol',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '20.4',
                                limits: '18,5 - 22,5',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'mchc',
                                    short_text: 'MCHC',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'mmol/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '8.80',
                                limits: '4 - 9',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'leuko',
                                    short_text: 'Leukozyten',
                                    long_text: '',
                                },
                                unit: {
                                    code: '10E9/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '378',
                                limits: '150 - 450',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'plt',
                                    short_text: 'Thrombozyten',
                                    long_text: '',
                                },
                                unit: {
                                    code: '10E9/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '10.3',
                                limits: '9 - 13',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'mpv',
                                    short_text: 'Mittleres Thrombozytenvolumen',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'fl',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                        ],
                    },
                    {
                        text_short: 'Chemie',
                        text_long: 'Klinische Chemie',
                        icon_name: '',
                        color: 'orange',
                        sort_order: 10,
                        values: [
                            {
                                value: '15.9',
                                limits: '<35',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'asat',
                                    short_text: 'ASAT (GOT)',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'U/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '15.4',
                                limits: '<35',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'alat',
                                    short_text: 'ALAT (GPT)',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'U/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '16.9',
                                limits: '< 40',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ggt',
                                    short_text: 'Gamma-GT',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'U/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '37.5',
                                limits: '35 - 52',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'alb',
                                    short_text: 'Albumin',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'g/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '71.2',
                                limits: '66 - 87',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'prot',
                                    short_text: 'Protein',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'g/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '9.21',
                                limits: '<15',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'bilig',
                                    short_text: 'Bilirubin, gesamt',
                                    long_text: '',
                                },
                                unit: {
                                    code: '\u00b5mol/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                        ],
                    },
                    {
                        text_short: 'Endo',
                        text_long: 'Endokrinologie',
                        icon_name: '',
                        color: 'violett',
                        sort_order: 70,
                        values: [
                            {
                                value: '14.0',
                                limits: '3,1 - 6,8',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ft3',
                                    short_text: 'FT3',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'pmol/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '56.1',
                                limits: '11,9 - 21,6',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ft4',
                                    short_text: 'FT4',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'pmol/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '<0.050',
                                limits: '0,27 - 4,2',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'tsh',
                                    short_text: 'TSH',
                                    long_text: '',
                                },
                                unit: {
                                    code: '\u00b5IU/ml',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                        ],
                    },
                ],
            },
            {
                id: 20928,
                modified_at: '2024-09-04 13:56:25.818046+00:00',
                modified_by: 'oru-import',
                result_groups: 'H\u00e4ma, Chemie, Endo',
                received_date_time: '2024-09-04 13:56:25.818189+00:00',
                observation_date_time: '2024-09-02 13:56:25.818215+00:00',
                reported_date_time: '2024-09-04 13:56:25.818240+00:00',
                filler_order_number: '37484878',
                placer_order_number: '37484878',
                order_control: 'RE',
                verified_by: 'LABOR',
                groups: [
                    {
                        text_short: 'H\u00e4ma',
                        text_long: 'H\u00e4matologie',
                        icon_name: '',
                        color: 'rot',
                        sort_order: 20,
                        values: [
                            {
                                value: '8.0',
                                limits: '7,4 - 9,9',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'hb',
                                    short_text: 'H\u00e4moglobin',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'mmol/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '0.39',
                                limits: '0,35 - 0,47',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'hk',
                                    short_text: 'H\u00e4matokrit',
                                    long_text: '',
                                },
                                unit: {
                                    code: '',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '5.07',
                                limits: '3,85 - 5,2',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ery',
                                    short_text: 'Erythrozyten',
                                    long_text: '',
                                },
                                unit: {
                                    code: '10E12/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '77.3',
                                limits: '81 - 96',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'mcv',
                                    short_text: 'MCV',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'fl',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '1.58',
                                limits: '1,55 - 1,9',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'mch',
                                    short_text: 'MCH',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'fmol',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '20.4',
                                limits: '18,5 - 22,5',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'mchc',
                                    short_text: 'MCHC',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'mmol/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '8.80',
                                limits: '4 - 9',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'leuko',
                                    short_text: 'Leukozyten',
                                    long_text: '',
                                },
                                unit: {
                                    code: '10E9/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '378',
                                limits: '150 - 450',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'plt',
                                    short_text: 'Thrombozyten',
                                    long_text: '',
                                },
                                unit: {
                                    code: '10E9/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '10.3',
                                limits: '9 - 13',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'mpv',
                                    short_text: 'Mittleres Thrombozytenvolumen',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'fl',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                        ],
                    },
                    {
                        text_short: 'Chemie',
                        text_long: 'Klinische Chemie',
                        icon_name: '',
                        color: 'orange',
                        sort_order: 10,
                        values: [
                            {
                                value: '15.9',
                                limits: '<35',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'asat',
                                    short_text: 'ASAT (GOT)',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'U/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '15.4',
                                limits: '<35',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'alat',
                                    short_text: 'ALAT (GPT)',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'U/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '16.9',
                                limits: '< 40',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ggt',
                                    short_text: 'Gamma-GT',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'U/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '37.5',
                                limits: '35 - 52',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'alb',
                                    short_text: 'Albumin',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'g/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '71.2',
                                limits: '66 - 87',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'prot',
                                    short_text: 'Protein',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'g/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '9.21',
                                limits: '<15',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'bilig',
                                    short_text: 'Bilirubin, gesamt',
                                    long_text: '',
                                },
                                unit: {
                                    code: '\u00b5mol/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                        ],
                    },
                    {
                        text_short: 'Endo',
                        text_long: 'Endokrinologie',
                        icon_name: '',
                        color: 'violett',
                        sort_order: 70,
                        values: [
                            {
                                value: '14.0',
                                limits: '3,1 - 6,8',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ft3',
                                    short_text: 'FT3',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'pmol/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '56.1',
                                limits: '11,9 - 21,6',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ft4',
                                    short_text: 'FT4',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'pmol/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '<0.050',
                                limits: '0,27 - 4,2',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'tsh',
                                    short_text: 'TSH',
                                    long_text: '',
                                },
                                unit: {
                                    code: '\u00b5IU/ml',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                        ],
                    },
                ],
            },
            {
                id: 20978,
                modified_at: '2024-09-04 14:01:41.534029+00:00',
                modified_by: 'oru-import',
                result_groups: 'H\u00e4ma, Chemie, Endo',
                received_date_time: '2024-09-04 14:01:41.534255+00:00',
                observation_date_time: '2024-09-04 14:01:41.534287+00:00',
                reported_date_time: '2024-09-04 14:01:41.534313+00:00',
                filler_order_number: '37484878',
                placer_order_number: '37484878',
                order_control: 'RE',
                verified_by: 'LABOR',
                groups: [
                    {
                        text_short: 'H\u00e4ma',
                        text_long: 'H\u00e4matologie',
                        icon_name: '',
                        color: 'rot',
                        sort_order: 20,
                        values: [
                            {
                                value: '8.2',
                                limits: '7,4 - 9,9',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'hb',
                                    short_text: 'H\u00e4moglobin',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'mmol/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '0.39',
                                limits: '0,35 - 0,47',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'hk',
                                    short_text: 'H\u00e4matokrit',
                                    long_text: '',
                                },
                                unit: {
                                    code: '',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '5.07',
                                limits: '3,85 - 5,2',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ery',
                                    short_text: 'Erythrozyten',
                                    long_text: '',
                                },
                                unit: {
                                    code: '10E12/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '77.3',
                                limits: '81 - 96',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'mcv',
                                    short_text: 'MCV',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'fl',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '1.58',
                                limits: '1,55 - 1,9',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'mch',
                                    short_text: 'MCH',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'fmol',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '20.4',
                                limits: '18,5 - 22,5',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'mchc',
                                    short_text: 'MCHC',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'mmol/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '8.80',
                                limits: '4 - 9',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'leuko',
                                    short_text: 'Leukozyten',
                                    long_text: '',
                                },
                                unit: {
                                    code: '10E9/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '378',
                                limits: '150 - 450',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'plt',
                                    short_text: 'Thrombozyten',
                                    long_text: '',
                                },
                                unit: {
                                    code: '10E9/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '10.3',
                                limits: '9 - 13',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'mpv',
                                    short_text: 'Mittleres Thrombozytenvolumen',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'fl',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                        ],
                    },
                    {
                        text_short: 'Chemie',
                        text_long: 'Klinische Chemie',
                        icon_name: '',
                        color: 'orange',
                        sort_order: 10,
                        values: [
                            {
                                value: '15.9',
                                limits: '<35',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'asat',
                                    short_text: 'ASAT (GOT)',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'U/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '15.4',
                                limits: '<35',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'alat',
                                    short_text: 'ALAT (GPT)',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'U/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '16.9',
                                limits: '< 40',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ggt',
                                    short_text: 'Gamma-GT',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'U/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '37.5',
                                limits: '35 - 52',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'alb',
                                    short_text: 'Albumin',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'g/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '71.2',
                                limits: '66 - 87',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'prot',
                                    short_text: 'Protein',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'g/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '9.21',
                                limits: '<15',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'bilig',
                                    short_text: 'Bilirubin, gesamt',
                                    long_text: '',
                                },
                                unit: {
                                    code: '\u00b5mol/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                        ],
                    },
                    {
                        text_short: 'Endo',
                        text_long: 'Endokrinologie',
                        icon_name: '',
                        color: 'violett',
                        sort_order: 70,
                        values: [
                            {
                                value: '14.0',
                                limits: '3,1 - 6,8',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ft3',
                                    short_text: 'FT3',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'pmol/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '56.1',
                                limits: '11,9 - 21,6',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ft4',
                                    short_text: 'FT4',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'pmol/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '<0.050',
                                limits: '0,27 - 4,2',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'tsh',
                                    short_text: 'TSH',
                                    long_text: '',
                                },
                                unit: {
                                    code: '\u00b5IU/ml',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                        ],
                    },
                ],
            },
            {
                id: 21000,
                modified_at: '2024-09-10 10:15:32.123456+00:00',
                modified_by: 'oru-import',
                result_groups: 'H\u00e4ma, Chemie, Endo',
                received_date_time: '2024-09-10 10:15:32.123456+00:00',
                observation_date_time: '2024-09-10 10:15:32.123456+00:00',
                reported_date_time: '2024-09-10 10:15:32.123456+00:00',
                filler_order_number: '37484879',
                placer_order_number: '37484879',
                order_control: 'RE',
                verified_by: 'LABOR',
                groups: [
                    {
                        text_short: 'H\u00e4ma',
                        text_long: 'H\u00e4matologie',
                        icon_name: '',
                        color: 'rot',
                        sort_order: 20,
                        values: [
                            {
                                value: '7.6',
                                limits: '7,4 - 9,9',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'hb',
                                    short_text: 'H\u00e4moglobin',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'mmol/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '0.38',
                                limits: '0,35 - 0,47',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'hk',
                                    short_text: 'H\u00e4matokrit',
                                    long_text: '',
                                },
                                unit: {
                                    code: '',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '5.15',
                                limits: '3,85 - 5,2',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ery',
                                    short_text: 'Erythrozyten',
                                    long_text: '',
                                },
                                unit: {
                                    code: '10E12/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '82.1',
                                limits: '81 - 96',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'mcv',
                                    short_text: 'MCV',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'fl',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                        ],
                    },
                    {
                        text_short: 'Chemie',
                        text_long: 'Klinische Chemie',
                        icon_name: '',
                        color: 'orange',
                        sort_order: 10,
                        values: [
                            {
                                value: '16.7',
                                limits: '<35',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'asat',
                                    short_text: 'ASAT (GOT)',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'U/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '14.9',
                                limits: '<35',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'alat',
                                    short_text: 'ALAT (GPT)',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'U/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                        ],
                    },
                ],
            },
            {
                id: 21001,
                modified_at: '2024-09-11 09:45:21.987654+00:00',
                modified_by: 'oru-import',
                result_groups: 'H\u00e4ma, Chemie, Endo',
                received_date_time: '2024-09-11 09:45:21.987654+00:00',
                observation_date_time: '2024-09-11 09:45:21.987654+00:00',
                reported_date_time: '2024-09-11 09:45:21.987654+00:00',
                filler_order_number: '37484880',
                placer_order_number: '37484880',
                order_control: 'RE',
                verified_by: 'LABOR',
                groups: [
                    {
                        text_short: 'Endo',
                        text_long: 'Endokrinologie',
                        icon_name: '',
                        color: 'violett',
                        sort_order: 70,
                        values: [
                            {
                                value: '3.9',
                                limits: '3,1 - 6,8',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ft3',
                                    short_text: 'FT3',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'pmol/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '20.8',
                                limits: '11,9 - 21,6',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ft4',
                                    short_text: 'FT4',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'pmol/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '0.85',
                                limits: '0,27 - 4,2',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'tsh',
                                    short_text: 'TSH',
                                    long_text: '',
                                },
                                unit: {
                                    code: '\u00b5IU/ml',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                        ],
                    },
                    {
                        text_short: 'Chemie',
                        text_long: 'Klinische Chemie',
                        icon_name: '',
                        color: 'orange',
                        sort_order: 10,
                        values: [
                            {
                                value: '15.3',
                                limits: '<35',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'asat',
                                    short_text: 'ASAT (GOT)',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'U/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '10.8',
                                limits: '<35',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'alat',
                                    short_text: 'ALAT (GPT)',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'U/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                        ],
                    },
                ],
            },
            {
                id: 21002,
                modified_at: '2024-09-12 09:30:45.000000+00:00',
                modified_by: 'oru-import',
                result_groups: 'Häma, Chemie, Immuno',
                received_date_time: '2024-09-12 09:30:45.000000+00:00',
                observation_date_time: '2024-09-12 09:30:45.000000+00:00',
                reported_date_time: '2024-09-12 09:30:45.000000+00:00',
                filler_order_number: '37484900',
                placer_order_number: '37484900',
                order_control: 'RE',
                verified_by: 'LABOR',
                groups: [
                    {
                        text_short: 'Immuno',
                        text_long: 'Immunology',
                        icon_name: '',
                        color: 'green',
                        sort_order: 30,
                        values: [
                            {
                                value: '4.1',
                                limits: '2 - 5',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'igG',
                                    short_text: 'IgG',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'g/L',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '0.9',
                                limits: '0.5 - 1.5',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'igM',
                                    short_text: 'IgM',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'g/L',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '3.5',
                                limits: '1.1 - 3.2',
                                status: 'A',
                                abnormal_flags: 'H',
                                method: {
                                    code: 'c-reactive protein',
                                    short_text: 'CRP',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'mg/dL',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments:
                                    'Slight elevation noted, further investigation advised.',
                            },
                        ],
                    },
                    {
                        text_short: 'Chemie',
                        text_long: 'Klinische Chemie',
                        icon_name: '',
                        color: 'orange',
                        sort_order: 10,
                        values: [
                            {
                                value: '12.0',
                                limits: '8 - 12',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'bun',
                                    short_text: 'Blood Urea Nitrogen',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'mg/dL',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                        ],
                    },
                ],
            },
            {
                id: 21003,
                modified_at: '2024-09-14 11:45:30.000000+00:00',
                modified_by: 'oru-import',
                result_groups: 'Häma, Chemie, Endo',
                received_date_time: '2024-09-14 11:45:30.000000+00:00',
                observation_date_time: '2024-09-14 11:45:30.000000+00:00',
                reported_date_time: '2024-09-14 11:45:30.000000+00:00',
                filler_order_number: '37484910',
                placer_order_number: '37484910',
                order_control: 'RE',
                verified_by: 'LABOR',
                groups: [
                    {
                        text_short: 'Endo',
                        text_long: 'Endokrinologie',
                        icon_name: '',
                        color: 'violett',
                        sort_order: 70,
                        values: [
                            {
                                value: '8.5',
                                limits: '3,1 - 6,8',
                                status: 'A',
                                abnormal_flags: 'H',
                                method: {
                                    code: 'ft3',
                                    short_text: 'FT3',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'pmol/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments:
                                    'Elevated FT3 levels, suggest further evaluation of thyroid function.',
                            },
                            {
                                value: '25.6',
                                limits: '11,9 - 21,6',
                                status: 'A',
                                abnormal_flags: 'H',
                                method: {
                                    code: 'ft4',
                                    short_text: 'FT4',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'pmol/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '0.02',
                                limits: '0,27 - 4,2',
                                status: 'A',
                                abnormal_flags: 'L',
                                method: {
                                    code: 'tsh',
                                    short_text: 'TSH',
                                    long_text: '',
                                },
                                unit: {
                                    code: '\u00b5IU/ml',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: 'Low TSH, suggests hyperthyroidism.',
                            },
                        ],
                    },
                    {
                        text_short: 'Chemie',
                        text_long: 'Klinische Chemie',
                        icon_name: '',
                        color: 'orange',
                        sort_order: 10,
                        values: [
                            {
                                value: '1.0',
                                limits: '0.7 - 1.3',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'creatinine',
                                    short_text: 'Creatinine',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'mg/dL',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '140.0',
                                limits: '135 - 145',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'sodium',
                                    short_text: 'Sodium',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'mmol/L',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                        ],
                    },
                ],
            },
            {
                id: 21004,
                modified_at: '2024-09-15 12:05:15.000000+00:00',
                modified_by: 'oru-import',
                result_groups: 'Häma, Chemie, Gerinnung',
                received_date_time: '2024-09-15 12:05:15.000000+00:00',
                observation_date_time: '2024-09-15 12:05:15.000000+00:00',
                reported_date_time: '2024-09-15 12:05:15.000000+00:00',
                filler_order_number: '37484920',
                placer_order_number: '37484920',
                order_control: 'RE',
                verified_by: 'LABOR',
                groups: [
                    {
                        text_short: 'Gerinnung',
                        text_long: 'Gerinnungsprofil',
                        icon_name: '',
                        color: 'blue',
                        sort_order: 40,
                        values: [
                            {
                                value: '1.2',
                                limits: '0.8 - 1.2',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'inr',
                                    short_text: 'INR',
                                    long_text: '',
                                },
                                unit: {
                                    code: '',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '26.0',
                                limits: '25 - 35',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ptt',
                                    short_text: 'Partial Thromboplastin Time',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'seconds',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                        ],
                    },
                    {
                        text_short: 'Chemie',
                        text_long: 'Klinische Chemie',
                        icon_name: '',
                        color: 'orange',
                        sort_order: 10,
                        values: [
                            {
                                value: '4.5',
                                limits: '3.5 - 5.1',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'potassium',
                                    short_text: 'Potassium',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'mmol/L',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '105.0',
                                limits: '98 - 107',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'chloride',
                                    short_text: 'Chloride',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'mmol/L',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                        ],
                    },
                ],
            },
            {
                id: 21005,
                modified_at: '2024-09-16 14:20:10.000000+00:00',
                modified_by: 'oru-import',
                result_groups: 'Häma, Chemie, Endo',
                received_date_time: '2024-09-16 14:20:10.000000+00:00',
                observation_date_time: '2024-09-16 14:20:10.000000+00:00',
                reported_date_time: '2024-09-16 14:20:10.000000+00:00',
                filler_order_number: '37484930',
                placer_order_number: '37484930',
                order_control: 'RE',
                verified_by: 'LABOR',
                groups: [
                    {
                        text_short: 'Endo',
                        text_long: 'Endokrinologie',
                        icon_name: '',
                        color: 'violett',
                        sort_order: 70,
                        values: [
                            {
                                value: '7.8',
                                limits: '3.1 - 6.8',
                                status: 'A',
                                abnormal_flags: 'H',
                                method: {
                                    code: 'ft3',
                                    short_text: 'FT3',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'pmol/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: 'FT3 levels slightly elevated.',
                            },
                            {
                                value: '20.3',
                                limits: '11.9 - 21.6',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ft4',
                                    short_text: 'FT4',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'pmol/l',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                        ],
                    },
                    {
                        text_short: 'Chemie',
                        text_long: 'Klinische Chemie',
                        icon_name: '',
                        color: 'orange',
                        sort_order: 10,
                        values: [
                            {
                                value: '4.2',
                                limits: '3.6 - 4.8',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'albumin',
                                    short_text: 'Albumin',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'g/dL',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                            {
                                value: '135.0',
                                limits: '135 - 145',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'sodium',
                                    short_text: 'Sodium',
                                    long_text: '',
                                },
                                unit: {
                                    code: 'mmol/L',
                                    short_text: '',
                                    long_text: '',
                                },
                                comments: '',
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
