import {createSelector} from "@ngrx/store";
import {AppState} from "../appstate.model";

/** Selector to access the device feature state. */
export const selectDeviceFeature = (state: AppState) => state.device;

/** Selector to retrieve the device. */
export const selectDeviceId = createSelector(
  selectDeviceFeature,
  (deviceState) => deviceState.device.deviceId
);
